import { api } from "./api";
import { AttendancesGrade } from "../types";

interface ApiAttendancesGrade {
  student: {
    name: string;
  };
  attendances_grade: number;
  late_count: number;
  absent_count: number;
  percent_grade: number;
}

interface ApiData {
  data: ApiAttendancesGrade[];
}

type GetAttendancesGrades = (arg0: { facilitatorId: string }) => Promise<AttendancesGrade[] | void>;

export const getAttendancesGrades: GetAttendancesGrades = async ({ facilitatorId }) => {
  const token = localStorage.getItem("@teaching/auth-token");

  return await api
    .get<ApiData>(`/attendances_grades?facilitator_id=${facilitatorId}`, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response): AttendancesGrade[] => {
      return response.data.data.map((apiAttendancesGrade: ApiAttendancesGrade) => {
        return {
          student: apiAttendancesGrade.student,
          attendancesGrade: apiAttendancesGrade.attendances_grade,
          lateCount: apiAttendancesGrade.late_count,
          absentCount: apiAttendancesGrade.absent_count,
          percentGrade: apiAttendancesGrade.percent_grade,
        };
      });
    })
    .catch(error => {
      if (error.response) console.log(error.response);
      else if (error.request) console.log(error.request);
      else console.log("Error", error.message);
    });
};
