import { Box } from "@chakra-ui/react";
import { FunctionComponent, useState, useEffect } from "react";
import { useParams } from "react-router";
import { useFacilitatorId } from "../../hooks/facilitator-id";
import { useFacilitatorName } from "../../hooks/facilitator-name";
import useFetchBlockers from "../../hooks/fetch-blockers";

import useFetchPeriods from "../../hooks/fetch-periods";
import useQueryString from "../../hooks/query-string";
import Blockers from "./blockers";
import BlockersHeader from "./blockers-header";

const BlockersPanel: FunctionComponent = (): JSX.Element => {
  const [selectedPeriodId, setSelectedPeriodId] = useState("");
  const { facilitatorName } = useFacilitatorName();
  const { facilitatorId } = useFacilitatorId(facilitatorName);
  const { module } = useParams<{ module: string }>();
  const { periods } = useFetchPeriods(facilitatorId);
  const { blockers } = useFetchBlockers(facilitatorId, facilitatorName, selectedPeriodId);

  const [periodId, setPeriodId] = useQueryString("period_id", "");

  useEffect(() => {
    if (periodId) setSelectedPeriodId(periodId);
    else if (periods && periods.length > 0) setSelectedPeriodId(periods[0].id);
  }, [periods]);

  const handleWithPeriodChange = (newPeriodId: string) => {
    setSelectedPeriodId(newPeriodId);
    setPeriodId(newPeriodId);
  };

  return (
    <Box backgroundColor="#f8f8f8" minH="100%" paddingX="2rem" paddingY="2rem">
      {facilitatorId && module ? (
        <>
          <BlockersHeader
            module={module}
            selectedPeriodId={selectedPeriodId}
            handleWithPeriodChange={handleWithPeriodChange}
          />

          <Blockers checkins={blockers || undefined} />
        </>
      ) : (
        <p>
          O facilitador que você procura não existe, tente buscar pelos nomes disponíveis na barra
          lateral.
        </p>
      )}
    </Box>
  );
};

export default BlockersPanel;
