import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FunctionComponent, useState } from "react";
import { Box, Text, Flex, Divider, Textarea, FormControl, FormLabel, Button } from "@chakra-ui/react";
import { StatusOption } from "./edit-attendance-status.styles";
import { useForm, SubmitHandler } from "react-hook-form";
import { PreAttendance, Attendance } from "../../../types";
import { SaveAttendanceResult, saveAttendance } from "../../../services/attendance-status";
import { attendancesStatusValues, AttendanceStatus } from "../../../model/AttendanceStatus";
import { useModules } from "../../../hooks/modules";

type EditStatusFormData = {
  reason: string;
};

interface EditAttendanceStatusProps {
  attendance: PreAttendance | Attendance;
  attendedAt: string;
  onCancel: () => void;
  onSubmitAttendance?: (attendance: Attendance) => void;
  onSubmitPreAttendance?: (attendance: PreAttendance) => void;
}

const EditStatusFormSchema = yup.object().shape({
  reason: yup.string().required("Campo obrigatório"),
});

const EditAttendanceStatus: FunctionComponent<EditAttendanceStatusProps> = ({
  attendance,
  onCancel,
  attendedAt,
  onSubmitAttendance,
  onSubmitPreAttendance,
}): JSX.Element => {
  const { currentModule } = useModules();
  const [currentOption, setCurrentOption] = useState(0);
  const [newStatus, setNewStatus] = useState(attendance.status.availableEditions[0]);
  const [submitStatus, setSubmitStatus] = useState<SaveAttendanceResult>({
    isSuccess: true,
    error: null,
  });

  const isAttendance = (attendance: Attendance | PreAttendance): attendance is Attendance => {
    return (attendance as Attendance).attendedAt !== undefined;
  };

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(EditStatusFormSchema),
  });

  const handleEditStatus: SubmitHandler<EditStatusFormData> = async data => {
    const result = await saveAttendance({
      attendanceId: attendance.id,
      status: newStatus.name,
      attendedAt: attendedAt,
      moduleId: currentModule,
      studentId: attendance.student.id,
      ...data,
    });

    if (result.isSuccess) {
      const updatedAttendance = {
        ...attendance,
        status: new AttendanceStatus(attendancesStatusValues[newStatus.name]),
      };

      if (isAttendance(updatedAttendance) && onSubmitAttendance) onSubmitAttendance(updatedAttendance);
      else if (!isAttendance(updatedAttendance) && onSubmitPreAttendance)
        onSubmitPreAttendance(updatedAttendance);
    } else setSubmitStatus(result);
  };

  return (
    <Box w="100%" textAlign="center">
      <Divider w="25rem" margin="0 auto" marginY="1rem" />
      <Text fontWeight="bold" fontSize="1rem" marginY="1rem">
        Edição de Status
      </Text>
      <Text marginBottom="1rem" fontSize="0.75rem">
        Selecione um Novo Status:
      </Text>

      <Box as="form" onSubmit={handleSubmit(handleEditStatus)}>
        <Flex alignItems="center" justifyContent="center">
          {attendance.status.availableEditions.map((attendanceStatus, index) => (
            <StatusOption
              key={index}
              selected={currentOption === index}
              status={attendanceStatus.name}
              onClick={() => {
                setCurrentOption(index);
                setNewStatus(attendanceStatus);
              }}
            >
              <p>{attendanceStatus.logo}</p>
            </StatusOption>
          ))}
        </Flex>

        <FormControl marginTop="2rem">
          <FormLabel textAlign="center" fontSize="0.75rem" color="#23303D">
            Justificativa:
          </FormLabel>
          <Textarea data-testid="text-reason" w="23.25rem" minH="7rem" {...register("reason")} />
        </FormControl>

        <Flex direction="column" alignItems="center">
          {submitStatus.isSuccess === false && (
            <Text margin="1rem auto" textAlign="center" fontSize="1rem" color="#FA463C">
              {submitStatus.error}
            </Text>
          )}
          <Button
            w="13.75rem"
            fontSize="1.25rem"
            marginTop="1.5rem"
            bgColor="#00B2FF"
            borderRadius="1.25rem"
            color="#FFF"
            type="submit"
          >
            Salvar
          </Button>
          <Button
            w="3.5rem"
            marginTop="1rem"
            fontSize="1rem"
            bgColor="transparent"
            color="#3A3A3A"
            textDecor="underline"
            _hover={{ backgroundColor: "transparent", textDecoration: "none" }}
            onClick={onCancel}
          >
            Cancelar
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default EditAttendanceStatus;
