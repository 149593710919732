import { eachDayOfInterval, isSameDay, format } from "date-fns";
import { AttendanceStatus } from "../../../model/AttendanceStatus";
import { Attendance } from "../../../types";
import { Container } from "./month.style";
import DayOfTheMonth from "../day-of-the-month/day-of-the-month";

interface MonthProps {
  attendances: Attendance[];
  startDate: string;
  endDate: string;
  refetchAttendances: () => void;
  refetchAttendancesGrade: () => void;
}

const Month = ({
  attendances,
  startDate,
  endDate,
  refetchAttendances,
  refetchAttendancesGrade,
}: MonthProps): JSX.Element => {
  const daysOfTheMonth = eachDayOfInterval({
    start: new Date(`${startDate}T00:00:00`),
    end: new Date(`${endDate}T00:00:00`),
  });

  return attendances ? (
    <Container>
      {daysOfTheMonth.map((date, index) => {
        const attendance = attendances?.find(a => isSameDay(new Date(`${a.attendedAt}T00:00:00`), date));
        const isDateValid = !!attendance?.insertedAt;

        const refetches = {
          refetchAttendances: refetchAttendances,
          refetchAttendancesGrade: refetchAttendancesGrade,
        };

        const noAttendanceData = {
          attendedAt: format(date, "yyyy-MM-dd").toString(),
          student: {
            id: "",
            name: "",
          },
          status: new AttendanceStatus("present"),
          id: "",
          insertedAt: "",
          updatedAt: "",
        };

        return isDateValid ? (
          attendance && (
            <DayOfTheMonth attendance={attendance} disabled={false} key={index} refetches={refetches} />
          )
        ) : (
          <DayOfTheMonth
            attendance={noAttendanceData}
            disabled={true}
            key={index}
            refetches={refetches}
          />
        );
      })}
    </Container>
  ) : (
    <></>
  );
};

export default Month;
