import { useQuery } from "react-query";
import { getPeriods } from "../services/periods";

const useFetchPeriods = (facilitatorId: string | null) => {
  const { data, isSuccess, error, isLoading, isError } = useQuery(["periods", { facilitatorId }], () => {
    if (facilitatorId) return getPeriods({ facilitatorId });

    throw new Error("Facilitator not found");
  });

  return { periods: data, isSuccess, error, isLoading, isError };
};

export default useFetchPeriods;
