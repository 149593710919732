import { Box, Text, Link, Icon, Divider } from "@chakra-ui/react";
import { HiOutlinePencil } from "react-icons/hi";

interface AddCommentButtonProps {
  onClick: () => void;
}

const AddCommentButton = ({ onClick }: AddCommentButtonProps) => {
  return (
    <Box w="90%">
      <Divider w="25rem" margin="0 auto" marginY="1rem" />
      <Link
        display="flex"
        onClick={onClick}
        borderRadius="0.5rem"
        p="0.325rem"
        w="fit-content"
        _hover={{ backgroundColor: "#F8F8F8", textDecoration: "none" }}
      >
        <Text fontSize="0.625rem" marginRight="0.25rem">
          Adicionar Comentário
        </Text>
        <Icon alignSelf="center" fontSize="0.7rem" as={HiOutlinePencil} />
      </Link>
    </Box>
  );
};

export default AddCommentButton;
