import { useState } from "react";
import { Attendance } from "../../../types";
import { getDayFromDate } from "../../../utils/format-date";
import { Container, TextBox, Text, EditFlag } from "./day-of-the-month.style";
import AttendanceStatusModal from "../../attendances/attendance-status/attendance-status-modal";

interface refetches {
  refetchAttendances: () => void;
  refetchAttendancesGrade: () => void;
}

interface DayOfMonthProps {
  attendance: Attendance;
  disabled: boolean;
  refetches: refetches;
}

const DayOfTheMonth = ({ attendance, disabled, refetches }: DayOfMonthProps): JSX.Element => {
  const day = getDayFromDate(`${attendance.attendedAt}T00:00:00`);
  const status = attendance.status;
  const isEdited = attendance.insertedAt !== attendance.updatedAt;
  const [showEditAttendanceModal, setShowEditAttendanceModal] = useState(false);

  const { refetchAttendances, refetchAttendancesGrade } = refetches;

  const handleCloseModal = () => {
    setShowEditAttendanceModal(false);
  };

  return (
    <>
      <Container>
        {disabled ? (
          <TextBox status={status.name} isEdited={isEdited} disabled={disabled}>
            {isEdited && <EditFlag data-testid="editionFlag" />}
            <Text status={status.name} disabled={disabled}>
              {day}
            </Text>
          </TextBox>
        ) : (
          <TextBox
            onClick={() => setShowEditAttendanceModal(true)}
            status={status.name}
            isEdited={isEdited}
            disabled={disabled}
          >
            {isEdited && <EditFlag data-testid="editionFlag" />}
            <Text status={status.name} disabled={disabled}>
              {day}
            </Text>
          </TextBox>
        )}
      </Container>
      {showEditAttendanceModal && (
        <AttendanceStatusModal
          currentAttendance={attendance}
          modalOpened={showEditAttendanceModal}
          handleCloseModal={handleCloseModal}
          chosenDate={new Date(`${attendance.attendedAt}T00:00:00`)}
          refetchAttendances={refetchAttendances}
          refetchAttendancesGrade={refetchAttendancesGrade}
        />
      )}
    </>
  );
};

export default DayOfTheMonth;
