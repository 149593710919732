import styled from "styled-components";

export const PlaygroundContainer = styled.div.withConfig({ displayName: "PlaygroundContainer" })`
  background-color: #f4f6fb;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: black;
`;
