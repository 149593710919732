import { useQuery } from "react-query";
import { DateTime } from "../utils/format-date";
import { getCheckins } from "../services/checkins";

const useFetchPreAttendances = (facilitatorId: string | null, date?: Date) => {
  const { data, isSuccess, error, isLoading, isError, refetch } = useQuery(
    ["preAttendances", { facilitatorId, date }],
    async () => {
      let day;
      if (date) day = new DateTime(date).toISO8601String();

      if (facilitatorId) {
        return await getCheckins({
          facilitatorId,
          attendedAt: day,
        });
      }
    },
    { retry: false }
  );

  return { preAttendances: data, isSuccess, error, isLoading, isError, refetch };
};

export default useFetchPreAttendances;
