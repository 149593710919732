import { Box } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";

import LoginBox from "../components/login-box/login-box";

const LoginPage = () => {
  return (
    <Box background="#23303D" h="100vh" w="100vw">
      <Flex alignItems="center" justifyContent="center" h="100%" pb="10vh">
        <LoginBox />
      </Flex>
    </Box>
  );
};

export default LoginPage;
