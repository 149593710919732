import { FC } from "react";
import { Table as ChakraTable } from "@chakra-ui/react";
import { Thead } from "@chakra-ui/react";
import { Tbody } from "@chakra-ui/react";
import { Tr } from "@chakra-ui/react";
import { Th } from "@chakra-ui/react";

interface TableProps {
  headers: string[];
}

const Table: FC<TableProps> = ({ children, headers }) => {
  return (
    <ChakraTable variant="simple">
      <Thead>
        <Tr>
          {headers.map((header, index) => (
            <Th key={index} minW="4rem">
              {header}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>{children}</Tbody>
    </ChakraTable>
  );
};

export default Table;
