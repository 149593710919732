import { useQuery } from "react-query";
import { getNonSchoolDays } from "../services/non_school_days";

const useFetchNonSchoolDays = () => {
  const { data, isSuccess, error, isLoading, isError } = useQuery(
    ["nonSchoolDays"],
    async () => {
      return await getNonSchoolDays();
    },
    { retry: false }
  );

  return { nonSchoolDays: data, isSuccess, error, isLoading, isError };
};

export default useFetchNonSchoolDays;
