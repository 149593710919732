import { api } from "../services/api";
import { Module } from "../types";

type GetModules = () => Promise<Module[]>;

export const getModules: GetModules = async () => {
  const { data } = await api.get("/modules");

  return data.data;
};
