import { ListItem } from "@chakra-ui/react";
import { Link } from "@chakra-ui/react";
import { FC } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";

import { Facilitator } from "../../types";
import { useModules } from "../../hooks/modules";

interface FacilitatorsProps {
  facilitators: Facilitator[];
  module: string;
  moduleId: string;
}

const Facilitators: FC<FacilitatorsProps> = ({ facilitators, module, moduleId }) => {
  const { facilitator: currFacilitator } = useParams<{ facilitator: string }>();
  const { setCurrentModule } = useModules();

  return (
    <>
      {facilitators
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .map((facilitator, index) => {
          const facilitatorName = facilitator.name.toLowerCase().split(" ").join("-");
          const moduleName = module.toLowerCase();

          return (
            <Link
              as={RouterLink}
              to={`/${moduleName}/${facilitatorName}`}
              className={module + "-facilitator"}
              fontSize="0.75rem"
              key={index}
              color={currFacilitator === facilitatorName ? "#f2f2f2" : "inherit"}
              onClick={() => setCurrentModule(moduleId)}
            >
              <ListItem key={index}>{facilitator.name}</ListItem>
            </Link>
          );
        })}
    </>
  );
};

export default Facilitators;
