import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 2rem;
  gap: 2rem;

  margin: 0 auto;
  margin-left: 16rem;
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2rem;
  background: none;
  border: none;
  gap: 0.5rem;
  font-size: 0.75rem;

  * {
    cursor: pointer;
  }
`;

export const PreviousMonthButton = styled(Button)`
  justify-content: flex-start;
`;

export const NextMonthButton = styled(Button)`
  justify-content: flex-end;
`;

export const CurrentMonthButton = styled(Button)`
  justify-content: flex-end;
`;
