import { useJwt } from "react-jwt";

const useAuthenticationToken = () => {
  const token = localStorage.getItem("@teaching/auth-token");

  if (!token) return { isTokenValid: false };

  const { isExpired } = useJwt(token);
  return { isTokenValid: !isExpired };
};

export default useAuthenticationToken;
