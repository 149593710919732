import { MouseEvent, FC } from "react";
import { Badge, Text, Flex } from "@chakra-ui/react";
import { Period } from "../../types";

interface PeriodSelectorProps {
  selectedPeriodId: string;
  handleWithPeriodChange: (period_id: string) => void;
  periods: Period[];
}

const PeriodSelector: FC<PeriodSelectorProps> = ({
  selectedPeriodId,
  handleWithPeriodChange,
  periods,
}) => {
  const handleChange = (e: MouseEvent<HTMLSelectElement>) => {
    const badge = e.target as HTMLElement;
    handleWithPeriodChange(badge.id);
  };

  return (
    <Flex direction="column" justifyContent="center">
      <Text fontSize="0.75rem" fontWeight="semibold">
        Período:
      </Text>
      <Flex>
        {periods.map((period, index) => (
          <Badge
            key={index}
            onClick={handleChange}
            id={period.id}
            backgroundColor={selectedPeriodId === period.id ? "#23303D" : "#FFFFFF"}
            color={selectedPeriodId === period.id ? "#FFFFFF" : "#23303D"}
            mr="1rem"
            padding="0.25rem 0.5em"
            borderRadius="0.5rem"
            cursor="pointer"
          >
            {period.name.toUpperCase()}
          </Badge>
        ))}
      </Flex>
    </Flex>
  );
};

export default PeriodSelector;
