import { useQuery } from "react-query";
import { getBlockers } from "../services/blockers";

const useFetchBlockers = (
  facilitatorId: string | null,
  facilitatorName: string,
  selectedPeriodId: string
) => {
  const { data, isSuccess, error, isLoading, isError } = useQuery(
    ["blockers", { facilitatorName, selectedPeriodId }],
    async () => {
      if (facilitatorId) {
        return getBlockers({
          facilitatorId,
          periodId: selectedPeriodId,
        });
      }
    },
    { retry: false, enabled: !!selectedPeriodId }
  );

  return { blockers: data, isSuccess, error, isLoading, isError };
};

export default useFetchBlockers;
