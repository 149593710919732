import { Link } from "react-router-dom";
import styled from "styled-components";

interface TabProps {
  selected: boolean;
}

export const Flex = styled.div`
  display: flex;
`;

export const Tab = styled(Link)<TabProps>`
  margin-right: 1rem;
  background-color: ${props => (props.selected ? "#f8f8f8" : "#ffffff")};
  font-weight: ${props => (props.selected ? "bold" : "initial")};
  padding: 0.5rem 2rem;
  border-radius: 1rem 1rem 0 0;
`;
