import { useParams } from "react-router-dom";

export const useFacilitatorName = () => {
  const { facilitator } = useParams<{ facilitator: string }>();

  const facilitatorName = facilitator
    ?.replace(/-/g, " ")
    .replace(/(?:^|\s)\S/g, char => char.toUpperCase());

  return { facilitatorName: facilitatorName || "" };
};
