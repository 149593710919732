import { useState, createContext, FC, Dispatch, SetStateAction, useContext } from "react";

import { Module } from "../types";

interface ModuleData {
  modules: Module[] | [];
  setModules: Dispatch<SetStateAction<Module[]>>;
  blockModulesView: string[];
  setBlockModulesView: Dispatch<SetStateAction<string[]>>;
  currentModule: string;
  setCurrentModule: Dispatch<SetStateAction<string>>;
}

export const ModulesContext = createContext<ModuleData>({
  modules: [],
  setModules: () => {},
  blockModulesView: [],
  setBlockModulesView: () => {},
  currentModule: "",
  setCurrentModule: () => {},
});

export const useModules = () => useContext(ModulesContext);

export const ModulesProvider: FC = ({ children }) => {
  const [modules, setModules] = useState<Module[] | []>([]);
  const [currentModule, setCurrentModule] = useState("");
  const [blockModulesView, setBlockModulesView] = useState([
    "Q4 A",
    "Q4",
    "Q2",
    "M1 A",
    "M1 B",
    "FS007-Mai-21-Q3-A",
    "FE001-Set-21-M1 noturno",
    "FS009-Set-21-M3",
    "FS010-Nov-21-M2",
    "FS011-Jan-22-M1-A",
    "FS011-Jan-22-M1-B",
    "FS006-Mar-21-Q4-B",
    "FS008-Jul-21-Q3-B",
    "FE003-Jan-22-M1",
    "FS010-Nov-21-M1",
    "FS011-Jan-22-M1",
    "FE002-Nov-21-M3",
    "FE003-Jan-22-M2",
    "FS009-Set-21-M4",
    "FS010-Nov-21-M3",
    "FS011-Jan-22-M2",
    "FS012-Mar-22-M1",
  ]);

  return (
    <ModulesContext.Provider
      value={{
        modules,
        setModules,
        blockModulesView,
        setBlockModulesView,
        currentModule,
        setCurrentModule,
      }}
    >
      {children}
    </ModulesContext.Provider>
  );
};
