import { api } from "./api";
import { Comment } from "../types";

export interface CreateCommentResult {
  isSuccess: boolean;
  error: string | null;
}

type CreateComment = (arg0: {
  studentId: string;
  comment: string;
  date: string;
}) => Promise<CreateCommentResult>;

export const createComment: CreateComment = async ({ studentId, comment, date }) => {
  const token = localStorage.getItem("@teaching/auth-token");

  return await api
    .post(
      `/students/${studentId}/comments`,
      {
        date: date,
        comment: comment,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    )
    .then(() => {
      return { isSuccess: true, error: null };
    })
    .catch(error => {
      if (error.response) console.log(error.response);
      else if (error.request) console.log(error.request);
      else console.log("Error", error.message);
      return { isSuccess: false, error: error.message };
    });
};

interface ApiComment {
  comment: string;
  user: {
    name: string;
    role: "admin" | "facilitator";
  };
  date: string;
  commented_at: string;
}

interface ApiData {
  data: ApiComment[];
}

type GetComments = (arg0: {
  studentId: string;
  startDate?: string;
  endDate?: string;
}) => Promise<Comment[] | void>;

export const getComments: GetComments = async ({ studentId, startDate = "", endDate = "" }) => {
  const params = startDate && endDate ? `?start_date=${startDate}&end_date=${endDate}` : "";
  const token = localStorage.getItem("@teaching/auth-token");

  return await api
    .get<ApiData>(`/students/${studentId}/comments` + params, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response): Comment[] => {
      return response.data.data.map((apiComment: ApiComment) => {
        return {
          comment: apiComment.comment,
          user: apiComment.user,
          date: apiComment.date,
          commentedAt: apiComment.commented_at,
        };
      });
    })
    .catch(error => {
      if (error.response) console.log(error.response);
      else if (error.request) console.log(error.request);
      else console.log("Error", error.message);
    });
};
