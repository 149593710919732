import { FC } from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { WarningIcon } from "@chakra-ui/icons";
import { Text } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";

interface Detail {
  title: string;
  amount: number;
  color: string;
}

interface TitleProps {
  icon: "checkin" | "blocker";
  text: string;
  details: Detail[];
}

const Title: FC<TitleProps> = ({ icon, text, details }) => {
  return (
    <Flex alignItems="center">
      {icon === "checkin" && <CheckCircleIcon color="#3BB54A" />}
      {icon === "blocker" && <WarningIcon color="#FFB800" />}

      <Text display="flex" marginX="1rem" marginY="0" textTransform="uppercase" fontWeight="bold">
        {text}
      </Text>

      {details.map((detail, index) => {
        const paddedAmount = detail.amount.toString().padStart(2, "0");

        return (
          <Flex key={index} alignItems="center">
            <Text>
              <span style={{ color: detail.color, fontWeight: "bold" }}>{paddedAmount}</span>{" "}
              <span>{detail.title}</span>
            </Text>

            {index + 1 < details.length && (
              <Text fontWeight="bold" marginX="1rem" marginY="1rem" fontSize="1rem">
                /
              </Text>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default Title;
