import { Box } from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useFacilitatorId } from "../../hooks/facilitator-id";
import { useFacilitatorName } from "../../hooks/facilitator-name";
import useFetchPreAttendances from "../../hooks/fetch-pre-attendances";

import CheckinHeader from "./checkin-header";
import Checkins from "./checkins";
import useQueryString from "../../hooks/query-string";
import { DateTime } from "../../utils/format-date";

const CheckinsPanel: FunctionComponent = (): JSX.Element => {
  const [filterDate, setFilterDate] = useQueryString("attended_at", "");
  const [selectedDay, setSelectedDay] = useState(
    filterDate ? new Date(`${filterDate}T00:00:00`) : new Date()
  );
  const { facilitatorName } = useFacilitatorName();
  const { facilitatorId } = useFacilitatorId(facilitatorName);
  const { module } = useParams<{ module: string }>();

  const { preAttendances } = useFetchPreAttendances(facilitatorId, selectedDay);

  useEffect(() => {
    if (filterDate) setSelectedDay(new Date(`${filterDate}T00:00:00`));
    else setFilterDate(new DateTime().toISO8601String());
  }, []);

  const dateClickHandler = (date: Date) => {
    setSelectedDay(date);
    setFilterDate(new DateTime(date).toISO8601String());
  };

  return (
    <Box backgroundColor="#f8f8f8" minH="100%" paddingX="2rem" paddingY="2rem">
      {facilitatorId && module ? (
        <>
          <CheckinHeader
            module={module}
            dateChangeHandler={dateClickHandler}
            filter={{ day: selectedDay }}
            disabledDays={[0, 6]}
          />

          <Checkins attendances={preAttendances || undefined} chosenDate={selectedDay} />
        </>
      ) : (
        <p>
          O facilitador que você procura não existe, tente buscar pelos nomes disponíveis na barra
          lateral.
        </p>
      )}
    </Box>
  );
};

export default CheckinsPanel;
