import { Flex } from "@chakra-ui/react";
import Menu from "../components/menu/menu";
import MainContainer from "../components/main-container/main-container";

const CheckinPage = () => {
  return (
    <Flex direction="row">
      <Menu />
      <MainContainer />
    </Flex>
  );
};

export default CheckinPage;
