import { Box, Text } from "@chakra-ui/react";
import { FC } from "react";
import { AttendancesComments, AttendanceHistory } from "../../types";
import AttendanceHistoryComponent from "./attendance-history-component";
import CommentHistoryComponent from "./comment/comment-history-component";

interface HistoryProps {
  history: AttendancesComments[];
}

const History: FC<HistoryProps> = ({ history }) => {
  const isAttendance = (data: AttendancesComments): data is AttendanceHistory => {
    return (data as AttendanceHistory).reason !== undefined;
  };

  const getCommentDate = (x: AttendancesComments) => {
    if (isAttendance(x)) return x.changedAt;
    else return x.commentedAt;
  };

  const compareFn = (firstEl: AttendancesComments, secondEl: AttendancesComments) => {
    const keyA = new Date(getCommentDate(firstEl));
    const keyB = new Date(getCommentDate(secondEl));
    if (keyA < keyB) return 1;
    if (keyA > keyB) return -1;
    return 0;
  };

  return history.length > 0 ? (
    <>
      <Box width="85%" m="1rem 0 0 0">
        <Text fontWeight="bold" textAlign="center">
          Histórico
        </Text>
        {history
          ?.sort(compareFn)
          .map((data, index) =>
            isAttendance(data) ? (
              <AttendanceHistoryComponent data={data} position={history.length - index} key={index} />
            ) : (
              <CommentHistoryComponent data={data} position={history.length - index} key={index} />
            )
          )}
      </Box>
    </>
  ) : (
    <></>
  );
};

export default History;
