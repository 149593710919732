import { useQuery } from "react-query";
import { getComments } from "../services/comments";

const useFetchComments = (studentId: string, startDate?: string, endDate?: string) => {
  const { data, isSuccess, error, isLoading, isError, refetch } = useQuery(
    ["comments", { studentId, startDate, endDate }],
    async () => {
      return await getComments({
        studentId,
        startDate,
        endDate,
      });
    },
    { retry: false, enabled: !!studentId }
  );

  return { comments: data, isSuccess, error, isLoading, isError, refetchComments: refetch };
};

export default useFetchComments;
