import { format } from "date-fns";
import { Box, Text, HStack } from "@chakra-ui/react";
import { FC } from "react";
import { Comment } from "../../../types";

interface AttendanceHistoryProps {
  data: Comment;
  position: number;
}

const CommentHistoryComponent: FC<AttendanceHistoryProps> = ({ data, position }) => {
  const roleMap = {
    admin: "Administrador(a)",
    facilitator: "Facilitador(a)",
    instructor: "Instrutor(a)",
  };

  return (
    <HStack spacing="2rem" align="initial" marginTop="1.2rem">
      <Text fontWeight="bold">{`${position}º`}</Text>
      <Box>
        <Text>
          {roleMap[data.user.role]} <span style={{ fontWeight: "bold" }}>{data.user.name}</span> em{" "}
          <span style={{ fontWeight: "bold" }}>{format(new Date(data.commentedAt), "dd/MM/yyyy")}</span>{" "}
          às <span style={{ fontWeight: "bold" }}>{format(new Date(data.commentedAt), "HH:mm")}</span>
        </Text>
        <Text fontWeight="bold">Comentário:</Text>
        <Text>{data.comment}</Text>
      </Box>
    </HStack>
  );
};

export default CommentHistoryComponent;
