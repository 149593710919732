import { FC } from "react";

import { Flex } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/react";

import { Attendance, AttendancesGrade } from "../../types";
import AttendanceGradeRow from "./attendance-grade-row";
import Table from "../table/table";
import Card from "../card/card";
import MonthNavigation from "../calendar/month-navigation/month-navigation";
import useQueryString from "../../hooks/query-string";
import useFetchAttendances from "../../hooks/fetch-attendances";
import { useFacilitatorName } from "../../hooks/facilitator-name";
import { useFacilitatorId } from "../../hooks/facilitator-id";
import { format } from "date-fns";
import { getMonthStartAndLastDay } from "../../utils/format-date";

interface AttendancesGradesProps {
  attendancesGrades?: AttendancesGrade[];
  refetchAttendancesGrade: () => void;
}

const AttendancesGrades: FC<AttendancesGradesProps> = ({
  attendancesGrades,
  refetchAttendancesGrade,
}) => {
  const [currentMonth, setCurrentMonth] = useQueryString(
    "month",
    format(new Date(), "yyyy-MM").toString()
  );

  const onClick = (month: string) => {
    setCurrentMonth(month);
  };

  const filterAttendancesByStudentName = (
    attendances: Attendance[],
    studentAttendanceGrade: AttendancesGrade
  ) => {
    return attendances?.filter(
      attendance => attendance.student.name === studentAttendanceGrade.student.name
    );
  };

  const { monthName, firstDayOfMonth, lastDayOfMonth } = getMonthStartAndLastDay(currentMonth);
  const { facilitatorName } = useFacilitatorName();
  const { facilitatorId } = useFacilitatorId(facilitatorName);
  const { attendances, refetchAttendances } = useFetchAttendances(
    `${facilitatorId}`,
    firstDayOfMonth,
    lastDayOfMonth
  );
  const showGrades = !!attendancesGrades;

  return (
    <Card>
      <MonthNavigation onClick={onClick} />

      {showGrades && (
        <Flex direction="column" w="100%" minH="2rem">
          <Table headers={["Aluno", `${monthName}`, "Atrasos", "Faltas", "Nota"]}>
            {attendancesGrades?.map((attendancesGrade, index) => {
              return attendances ? (
                <AttendanceGradeRow
                  attendanceGrade={attendancesGrade}
                  key={index}
                  attendances={filterAttendancesByStudentName(attendances, attendancesGrade)}
                  startDate={firstDayOfMonth}
                  endDate={lastDayOfMonth}
                  refetchAttendances={refetchAttendances}
                  refetchAttendancesGrade={refetchAttendancesGrade}
                />
              ) : (
                <></>
              );
            })}
          </Table>
        </Flex>
      )}
      {!showGrades && (
        <>
          <Skeleton height="2rem" maxW="100%" mb="0.5rem" mt="3rem" />
          <Skeleton height="3rem" maxW="100%" mb="0.5rem" />
          <Skeleton height="3rem" maxW="100%" mb="0.5rem" />
          <Skeleton height="3rem" maxW="100%" mb="0.5rem" />
        </>
      )}
    </Card>
  );
};

export default AttendancesGrades;
