import styled from "styled-components";

export type JustifyContentType =
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "space-evenly";

export type AlignItemsType = "flex-start" | "flex-end" | "center" | "baseline" | "stretch ";

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  z-index: 20;
  top: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(22, 22, 22, 0.37);
`;

interface WrapperProps {
  width: number;
  height: number | "auto";
}

export const Wrapper = styled.div<WrapperProps>`
  background-color: #fff;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  max-height: 80vh;
`;

export const BtnClose = styled.button`
  color: #000;
  cursor: pointer;
  border: none;
  background-color: #fff;
  padding-right: 10px;
  padding-top: 8px;

  span {
    font-size: 28px;
  }
`;

interface ContentProps {
  contentMargin: number;
  contentMaxWidth: number;
  contentPaddingTop: number;
  contentJustifyContent: JustifyContentType;
  contentAlignItems: AlignItemsType;
}

export const Content = styled.div<ContentProps>`
  position: relative;
  margin: ${props => props.contentMargin}px;
  max-width: ${props => props.contentMaxWidth}px;
  padding-top: ${props => props.contentPaddingTop}px;
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.contentJustifyContent};
  align-items: ${props => props.contentAlignItems};
  color: #010000;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;
