import { FC } from "react";

import { Flex } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/react";

import Table from "../table/table";
import Title from "../title/title";
import Card from "../card/card";
import BlockerRow from "./blocker-row";
import useFilterBlockers from "../../hooks/filter-blockers";

import { Blocker } from "../../types";

interface BlockersProps {
  checkins?: Blocker[];
}

const Blockers: FC<BlockersProps> = ({ checkins }) => {
  const { blockers } = useFilterBlockers(checkins);

  return (
    <Card>
      <Title
        icon="blocker"
        text="Impedimentos"
        details={[{ title: "impedimento(s)", amount: blockers?.length || 0, color: "#FFB800" }]}
      />

      {blockers && blockers.length > 0 && (
        <Flex direction="column" w="100%" mt="2rem">
          <Table headers={["Aluno", "Impedimento", "Horário", "Atividade"]}>
            {blockers.map((checkin, index) => {
              if (checkin.currentBlockers && checkin.currentActivity) {
                return (
                  <BlockerRow
                    key={index}
                    name={checkin.student.name}
                    currentActivity={checkin.currentActivity}
                    currentBlockers={checkin.currentBlockers}
                    checkedAt={checkin.checkedAt || ""}
                  />
                );
              }
            })}
          </Table>
        </Flex>
      )}
      {!blockers && (
        <>
          <Skeleton height="2rem" maxW="100%" mb="0.5rem" mt="3rem" />
          <Skeleton height="3rem" maxW="100%" mb="0.5rem" />
          <Skeleton height="3rem" maxW="100%" mb="0.5rem" />
          <Skeleton height="3rem" maxW="100%" mb="0.5rem" />
        </>
      )}
    </Card>
  );
};

export default Blockers;
