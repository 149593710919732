import { FC } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ModulesProvider } from "./modules";

const queryClient = new QueryClient();

const AppProvider: FC = ({ children }) => {
  return (
    <ChakraProvider>
      <QueryClientProvider client={queryClient}>
        <ModulesProvider>{children}</ModulesProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
};

export default AppProvider;
