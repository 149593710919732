import React, { FunctionComponent } from "react";
import {
  Overlay,
  Content,
  BtnClose,
  Wrapper,
  Header,
  AlignItemsType,
  JustifyContentType,
} from "./modal.styles";

interface ModalProps {
  onClose: () => void;
  show: boolean;
  children: React.ReactNode;
  width?: number;
  height?: number | "auto";
  contentMargin?: number;
  contentMaxWidth?: number;
  contentPaddingTop?: number;
  contentJustifyContent?: JustifyContentType;
  contentAlignItems?: AlignItemsType;
}

const Modal: FunctionComponent<ModalProps> = ({
  show,
  onClose,
  children,
  width = 300,
  height = "auto",
  contentMargin = 1.75,
  contentJustifyContent = "center",
  contentAlignItems = "center",
  contentMaxWidth = 528,
  contentPaddingTop = 0,
}): JSX.Element | null => {
  if (show) {
    return (
      <Overlay>
        <Wrapper width={width} height={height}>
          <Header>
            <BtnClose data-testid="closeModal" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </BtnClose>
          </Header>

          <Content
            contentMargin={contentMargin}
            contentMaxWidth={contentMaxWidth}
            contentPaddingTop={contentPaddingTop}
            contentJustifyContent={contentJustifyContent}
            contentAlignItems={contentAlignItems}
          >
            {children}
          </Content>
        </Wrapper>
      </Overlay>
    );
  }
  return null;
};

export default Modal;
