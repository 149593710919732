import AppProvider from "./hooks";
import Router from "./components/router";
import Playground from "./components/playground/playground";

function App() {
  return (
    <>
      {process.env.NODE_ENV === "development" && eval(process.env.REACT_APP_PLAYGROUND || "") ? (
        <Playground />
      ) : (
        <AppProvider>
          <Router />
        </AppProvider>
      )}
    </>
  );
}

export default App;
