import { FunctionComponent, useState, useCallback } from "react";
import DayPicker, { DayModifiers } from "react-day-picker";
import { IoCalendarOutline } from "react-icons/io5";

import "react-day-picker/lib/style.css";
import { Container, CalendarDisplay } from "./date-picker.styles";

import { formatDayToString, DateTime } from "../../utils/format-date";
import { isWeekend } from "date-fns";
import { NonSchoolDay } from "../../types";

interface DatePickerProps {
  handleDayChange: (day: Date) => void;
  filter: {
    day: Date;
  };
  disabledDays: number[];
  nonSchoolDays: NonSchoolDay[] | void;
}

const DatePicker: FunctionComponent<DatePickerProps> = ({
  handleDayChange,
  filter,
  disabledDays,
  nonSchoolDays,
}): JSX.Element => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [isCalendarActive, setIsCalendarActive] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<Date>(filter.day);
  const [calendarDisplay, setCalendarDisplay] = useState<string>(
    formatDayToString(new Date(filter.day))
  );

  function handleCalendarInput() {
    setIsCalendarActive(!isCalendarActive);
  }

  function isSchoolDay(selectedDay: string) {
    const isNonSchoolDay = nonSchoolDays?.some(day => day.date == selectedDay);

    if (isNonSchoolDay || isWeekend(new Date(`${selectedDay}T00:00`))) return false;

    return true;
  }

  const nonSchoolDaysToHighlight = nonSchoolDays?.map(day => new Date(`${day.date}T00:00`));

  const handleClickDay = useCallback((day: Date, modifiers: DayModifiers) => {
    if (modifiers.available && isSchoolDay(new DateTime(day).toISO8601String())) {
      setSelectedDay(day);
      setCalendarDisplay(formatDayToString(day));

      handleDayChange(day);
    }
  }, []);

  return (
    <Container>
      <CalendarDisplay>
        <p>{calendarDisplay}</p>
        <button onClick={handleCalendarInput}>
          <IoCalendarOutline />
        </button>
      </CalendarDisplay>

      {isCalendarActive && (
        <DayPicker
          weekdaysShort={["D", "S", "T", "Q", "Q", "S", "S"]}
          modifiers={{
            available: { daysOfWeek: [1, 2, 3, 4, 5], before: tomorrow },
            disabled: { daysOfWeek: disabledDays },
            highlighted: nonSchoolDaysToHighlight,
          }}
          onDayClick={handleClickDay}
          selectedDays={selectedDay}
          months={[
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ]}
        />
      )}
    </Container>
  );
};

export default DatePicker;
