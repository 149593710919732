import { FC } from "react";
import styled from "styled-components";

const StyledGradeBox = styled.div`
  background-color: #23303d;
  color: #ffffff;
  text-align: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
`;
const PercentGrade = styled.p`
  font-weight: bold;
`;

const Grade = styled.span`
  font-size: 0.75rem;
  font-weight: 300;
  display: block;
`;

interface AttendanceTotalGradeProps {
  grade: string;
  percentGrade: string;
}

const AttendanceTotalGrade: FC<AttendanceTotalGradeProps> = ({ grade, percentGrade }) => {
  return (
    <StyledGradeBox>
      <PercentGrade>{`${percentGrade}%`}</PercentGrade>
      <Grade>({grade})</Grade>
    </StyledGradeBox>
  );
};

export default AttendanceTotalGrade;
