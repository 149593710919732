import { format } from "date-fns";
import { BsArrowRight } from "react-icons/bs";
import { Box, Text, Flex, HStack } from "@chakra-ui/react";
import AttendanceData from "../attendance-status/attendance-status";
import { FC } from "react";
import { AttendanceHistory } from "../../types";

interface AttendanceHistoryProps {
  data: AttendanceHistory;
  position: number;
}

const AttendanceHistoryComponent: FC<AttendanceHistoryProps> = ({ data, position }) => {
  const roleMap = {
    admin: "Administrador(a)",
    facilitator: "Facilitador(a)",
    instructor: "Instrutor(a)",
  };

  return (
    <HStack spacing="2rem" align="initial" marginTop="1.2rem">
      <Text fontWeight="bold">{`${position}º`}</Text>
      <Box>
        <Text>
          {roleMap[data.user.role]} <span style={{ fontWeight: "bold" }}>{data.user.name}</span> em{" "}
          <span style={{ fontWeight: "bold" }}>{format(new Date(data.changedAt), "dd/MM/yyyy")}</span> às{" "}
          <span style={{ fontWeight: "bold" }}>{format(new Date(data.changedAt), "HH:mm")}</span>
        </Text>
        <Flex direction="row" alignItems="center" marginY="0.8rem" justifyContent="space-evenly">
          <AttendanceData status={data.previousStatus} />
          <BsArrowRight />
          <AttendanceData status={data.newStatus} />
        </Flex>
        <Text fontWeight="bold">Justificativa:</Text>
        <Text>{data.reason}</Text>
      </Box>
    </HStack>
  );
};

export default AttendanceHistoryComponent;
