import { useQuery } from "react-query";
import { getAttendancesHistory } from "../services/attendances-history";

const useFetchAttendancesHistory = (attendanceId: string) => {
  const { data, isSuccess, error, isLoading, isError, refetch } = useQuery(
    ["attendancesHistory", { attendanceId }],
    async () => {
      return await getAttendancesHistory({
        attendanceId,
      });
    },
    { retry: false, enabled: !!attendanceId }
  );

  return { attendancesHistory: data, isSuccess, error, isLoading, isError, refetchAttendances: refetch };
};

export default useFetchAttendancesHistory;
