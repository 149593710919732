import { useEffect } from "react";
import { isThisMonth, format, addMonths, subMonths } from "date-fns";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdToday } from "react-icons/md";
import {
  Container,
  PreviousMonthButton,
  NextMonthButton,
  CurrentMonthButton,
} from "./month-navigation.style";
import { monthToNaiveDateTime } from "../../../utils/format-date";
import useQueryString from "../../../hooks/query-string";

interface MonthNavigationProps {
  onClick: (month: string) => void;
}

const MonthNavigation = ({ onClick }: MonthNavigationProps): JSX.Element => {
  const [dateOnUrl, setDateOnUrl] = useQueryString("month", format(new Date(), "yyyy-MM").toString());
  const isDateOnUrlThisMonth = isThisMonth(monthToNaiveDateTime(dateOnUrl));

  useEffect(() => {
    setDateOnUrl(dateOnUrl);
  }, []);

  const setNewDateOnUrl = (date: Date) => {
    const month = format(date, "yyyy-MM");
    setDateOnUrl(month);
    onClick(month);
  };

  const handleNextMonth = () => {
    const nextMonth = addMonths(monthToNaiveDateTime(dateOnUrl), 1);
    setNewDateOnUrl(nextMonth);
  };

  const handlePreviousMonth = () => {
    const previousMonth = subMonths(monthToNaiveDateTime(dateOnUrl), 1);
    setNewDateOnUrl(previousMonth);
  };

  const handleCurrentMonth = () => {
    setNewDateOnUrl(new Date());
  };

  return (
    <Container>
      <PreviousMonthButton onClick={handlePreviousMonth}>
        <IoIosArrowBack />
        <p>Mês Anterior</p>
      </PreviousMonthButton>

      <NextMonthButton onClick={handleNextMonth}>
        <p>Mês Seguinte</p>
        <IoIosArrowForward />
      </NextMonthButton>

      {!isDateOnUrlThisMonth && (
        <CurrentMonthButton onClick={handleCurrentMonth}>
          <p>Ir para o mês atual</p>
          <MdToday />
        </CurrentMonthButton>
      )}
    </Container>
  );
};

export default MonthNavigation;
