import { useParams } from "react-router-dom";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { List } from "@chakra-ui/react";

import Facilitators from "../facilitators/facilitators";
import { useModules } from "../../hooks/modules";
import { useEffect } from "react";

function Modules() {
  const { module: currModule = null } = useParams<{ module: string }>();

  const { modules, blockModulesView, setCurrentModule } = useModules();
  const modulesNames = modules
    .filter(module => !blockModulesView.includes(module.name))
    .map(module => module.name);
  const openedModule = modulesNames.findIndex(m => m.toUpperCase() == currModule?.toUpperCase());

  useEffect(() => {
    if (currModule != null) {
      const module = modules.find(module => module.name.toUpperCase() === currModule.toUpperCase());
      if (module) setCurrentModule(module.id);
    }
  }, [currModule]);

  return (
    <Accordion color="rgba(242, 242, 242, 0.5)" display="initial" defaultIndex={openedModule}>
      {modules.map((module, index) => {
        if (!blockModulesView.includes(module.name)) {
          return (
            <AccordionItem border="none" data-testid="modules-list" key={index}>
              <h2>
                <AccordionButton _expanded={{ color: "#f2f2f2" }}>
                  <AccordionIcon />
                  <Box flex="1" textAlign="left" pl="0.25rem" data-testid={module.name + "-module"}>
                    {module.name}
                  </Box>
                </AccordionButton>
              </h2>
              <AccordionPanel pb="0.5rem">
                <List pl="1.5rem" data-testid="facilitators-list">
                  <Facilitators
                    facilitators={module.facilitators}
                    module={module.name}
                    moduleId={module.id}
                  />
                </List>
              </AccordionPanel>
            </AccordionItem>
          );
        }
      })}
    </Accordion>
  );
}

export default Modules;
