import { Flex } from "@chakra-ui/react";
import Menu from "../components/menu/menu";
import Welcome from "../components/welcome/welcome";

const CheckinPage = () => {
  return (
    <Flex direction="row">
      <Menu />
      <Welcome />
    </Flex>
  );
};

export default CheckinPage;
