import styled from "styled-components";

export const Container = styled.div`
  height: 2rem;
  position: relative;
  box-sizing: border-box;

  .DayPicker-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 3rem;
    background: #ffffff;
    border-radius: 0.25rem;
  }

  .DayPicker-Caption {
    color: #000;
  }

  .DayPicker-Day {
    color: #c7c7c7;
  }

  .DayPicker-NavButton .DayPicker-NavButton--next {
    color: #000;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    color: #000;
  }

  .DayPicker-Day--selected {
    background: #0000ff !important;
    border-radius: 50%;
    color: #ffffff !important;
  }

  .DayPicker-Day--highlighted:hover {
    background: #ffcccc !important;
    border-radius: 50%;
    color: #ffffff !important;
    cursor: auto;
  }
`;

export const CalendarDisplay = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 80% 17%;
  justify-content: space-between;
  min-width: 17rem;

  color: #000000;

  p {
    margin: 0;
    padding: 0.5rem;
    text-align: center;
    background: #fff;
    font-size: 1rem;
    border-radius: 0.25rem 0 0 0.25rem;
  }

  button {
    background: #fff;
    border: none;
    border-radius: 0 0.25rem 0.25rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: filter 200ms;

    &:hover,
    &:focus {
      filter: brightness(0.9);
    }

    svg {
      font-size: 1.25rem;
    }
  }
`;
