import { api } from "./api";

interface ApiNonSchoolDay {
  date: string;
}

interface ApiData {
  data: ApiNonSchoolDay[];
}

type GetNonSchoolDays = () => Promise<ApiNonSchoolDay[] | void>;

export const getNonSchoolDays: GetNonSchoolDays = async () => {
  const token = localStorage.getItem("@teaching/auth-token");

  return await api
    .get<ApiData>("/non_school_days", {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response): ApiNonSchoolDay[] => {
      return response.data.data.map((apiNonSchoolDay: ApiNonSchoolDay) => {
        return {
          date: apiNonSchoolDay.date,
        };
      });
    })
    .catch(error => {
      if (error.response) console.log(error.response);
      else if (error.request) console.log(error.request);
      else console.log("Error", error.message);
    });
};
