import { FC } from "react";
import { Box } from "@chakra-ui/react";

const Card: FC = ({ children }) => {
  return (
    <Box backgroundColor="#FFFFFF" padding="2rem" borderRadius="1rem" mt="1rem" overflow="auto">
      {children}
    </Box>
  );
};

export default Card;
