import styled from "styled-components";
import { AttendanceStatusEnum } from "../../../model/AttendanceStatus";

interface TextBoxProps {
  status: AttendanceStatusEnum;
  isEdited: boolean;
  disabled: boolean;
}

const getTextBackground = (status: AttendanceStatusEnum, isEdited: boolean, isDisabled: boolean) => {
  const textColor = isDisabled
    ? "transparent"
    : status === AttendanceStatusEnum.ABSENT
    ? "#FA463C"
    : status === AttendanceStatusEnum.LATE
    ? "#F3D11D"
    : status === AttendanceStatusEnum.EXCUSED
    ? "#52D066"
    : status === AttendanceStatusEnum.PRESENT && isEdited
    ? "#B8CBDE"
    : "transparent";

  return textColor;
};

const getTextColor = (status: AttendanceStatusEnum, isDisabled: boolean) => {
  const textColor = isDisabled
    ? "#DDDDDD"
    : status === AttendanceStatusEnum.ABSENT ||
      status === AttendanceStatusEnum.LATE ||
      status === AttendanceStatusEnum.EXCUSED
    ? "#FFFFFF"
    : "#436586";

  return textColor;
};

const getCursor = (isDisabled: boolean) => {
  const cursor = isDisabled ? "grab" : "pointer";
  return cursor;
};

export const Container = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 0.25rem;
  cursor: default;
`;

export const TextBox = styled.article<TextBoxProps>`
  width: 100%;
  height: 100%;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => getTextBackground(props.status, props.isEdited, props.disabled)};

  position: relative;
  :hover {
    cursor: ${props => getCursor(props.disabled)};
  }
`;

interface TextProps {
  status: AttendanceStatusEnum;
  disabled: boolean;
}

export const Text = styled.p<TextProps>`
  margin: 0;
  font-size: 1rem;
  color: ${props => getTextColor(props.status, props.disabled)};
`;

export const EditFlag = styled.div`
  width: 0.25rem;
  height: 0.25rem;
  background-color: inherit;
  border-radius: 50%;
  position: absolute;
  top: -0.5rem;
  left: calc(50% - 0.125rem);
`;
