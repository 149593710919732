import { FC } from "react";

import { Tr } from "@chakra-ui/react";
import { Td } from "@chakra-ui/react";
import { format } from "date-fns";

interface BlockerRowProps {
  name: string;
  currentBlockers: string;
  currentActivity: string;
  checkedAt: string;
}

const BlockerRow: FC<BlockerRowProps> = ({ name, currentActivity, currentBlockers, checkedAt }) => {
  return (
    <Tr backgroundColor="#F8F8F8">
      <Td fontWeight="bold" w="18rem">
        {name}
      </Td>
      <Td>{currentBlockers}</Td>
      <Td>{format(new Date(checkedAt), "HH:mm")}</Td>
      <Td w="15rem">{currentActivity}</Td>
    </Tr>
  );
};

export default BlockerRow;
