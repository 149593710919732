import { Box, Text } from "@chakra-ui/react";
import { Flex, Tab } from "./main-container.styles";

import { useFacilitatorName } from "../../hooks/facilitator-name";
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from "react-router-dom";
import CheckinsPanel from "../checkins/checkins-panel";
import BlockersPanel from "../blockers/blockers-panel";
import AttendancesPanel from "../attendances/attendances-panel";

function MainContainer() {
  const tabNames = ["Check-ins", "Impedimentos", "Presenças"];
  const { facilitatorName } = useFacilitatorName();
  const { module, facilitator } = useParams<{ module: string; facilitator: string }>();
  const lastParam = useLocation().pathname.split("/").pop();
  const { path } = useRouteMatch();

  return (
    <Box id="div_body" height="100vh" overflow="auto" width="100%">
      <Box paddingX="2rem" paddingTop="3rem" position="relative">
        <Text fontSize="0.75rem" position="absolute" top="0.75rem">
          Bem vindo(a), {facilitatorName}
        </Text>
        <Text fontSize="1.75rem" fontWeight="bold" mb="2rem">
          Facilitador(a): {facilitatorName}
        </Text>

        <Flex>
          {tabNames.map((tab, index) => (
            <Tab
              selected={tab.toLowerCase() === lastParam}
              to={`/${module}/${facilitator}/${tab.toLowerCase()}`}
              key={index}
            >
              {tab}
            </Tab>
          ))}
        </Flex>
      </Box>

      <Switch>
        <Route path={`${path}/check-ins`} render={() => <CheckinsPanel />} />
        <Route path={`${path}/impedimentos`} render={() => <BlockersPanel />} />
        <Route path={`${path}/presenças`} render={() => <AttendancesPanel />} />
        <Redirect from={path} to={`${path}/check-ins`} />
      </Switch>
    </Box>
  );
}

export default MainContainer;
