import { useQuery } from "react-query";
import { getAttendancesGrades } from "../services/attendances-grades";

const useFetchAttendancesGrades = (facilitatorId: string | null) => {
  const { data, isSuccess, error, isLoading, isError, refetch } = useQuery(
    ["attendancesGrades", { facilitatorId }],
    async () => {
      if (facilitatorId) {
        return await getAttendancesGrades({
          facilitatorId,
        });
      }
    },
    { retry: false, enabled: !!facilitatorId }
  );

  return {
    attendancesGrades: data,
    isSuccess,
    error,
    isLoading,
    isError,
    refetchAttendancesGrade: refetch,
  };
};

export default useFetchAttendancesGrades;
