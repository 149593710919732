import { api } from "./api";
import { Blocker, Student } from "../types";

interface ApiBlockers {
  checked_at: string;
  current_activity: string;
  current_blockers: string;
  student: Student;
}

interface ApiData {
  data: ApiBlockers[];
}

type GetBlockers = (arg0: { facilitatorId: string; periodId: string }) => Promise<Blocker[] | void>;

export const getBlockers: GetBlockers = async ({ facilitatorId, periodId }) => {
  const params = `?facilitator_id=${facilitatorId}&period_id=${periodId}`;

  return await api
    .get<ApiData>("/blockers" + params)
    .then((response): Blocker[] =>
      response.data.data
        .map((checkin: ApiBlockers) => ({
          checkedAt: checkin.checked_at,
          currentBlockers: checkin.current_blockers,
          currentActivity: checkin.current_activity,
          student: checkin.student,
        }))
        .sort((a: Blocker, b: Blocker) => (a.student.name > b.student.name ? 1 : -1))
        .filter(
          (value, index, self) => self.findIndex(v => v.student.name === value.student.name) === index
        )
    )
    .catch(error => {
      if (error.response) console.log(error.response);
      else if (error.request) console.log(error.request);
      else console.log("Error", error.message);
    });
};
