export enum AttendanceStatusEnum {
  PRESENT,
  LATE,
  ABSENT,
  EXCUSED,
  AWAITING,
}

export const attendancesStatusValues = {
  [AttendanceStatusEnum.PRESENT]: "present",
  [AttendanceStatusEnum.LATE]: "late",
  [AttendanceStatusEnum.EXCUSED]: "excused",
  [AttendanceStatusEnum.ABSENT]: "absent",
  [AttendanceStatusEnum.AWAITING]: "awaiting",
};

type Logo = "P" | "At" | "F" | "Ab" | undefined;

export class AttendanceStatus {
  logo: Logo;
  name: AttendanceStatusEnum;
  availableEditions: AttendanceStatus[];
  isEditable: boolean;

  constructor(status: string, isEditable = true) {
    this.name = this.buildStatus(status);
    this.isEditable = isEditable;
    this.availableEditions = this.buildAvailableEditions();
    this.logo = this.buildLogo(this.name);
  }

  buildStatus(status: string) {
    return AttendanceStatusEnum[status.toUpperCase() as keyof typeof AttendanceStatusEnum];
  }

  buildAvailableEditions() {
    if (this.isEditable) {
      if (AttendanceStatusEnum.PRESENT === this.name) {
        return [
          new AttendanceStatus("late", false),
          new AttendanceStatus("absent", false),
          new AttendanceStatus("excused", false),
        ];
      }
      if (AttendanceStatusEnum.LATE === this.name) {
        return [
          new AttendanceStatus("present", false),
          new AttendanceStatus("absent", false),
          new AttendanceStatus("excused", false),
        ];
      }
      if (AttendanceStatusEnum.ABSENT === this.name) {
        return [
          new AttendanceStatus("present", false),
          new AttendanceStatus("late", false),
          new AttendanceStatus("excused", false),
        ];
      }
      if (AttendanceStatusEnum.EXCUSED === this.name) {
        return [
          new AttendanceStatus("late", false),
          new AttendanceStatus("absent", false),
          new AttendanceStatus("present", false),
        ];
      }

      return [];
    }

    return [];
  }

  buildLogo(status: AttendanceStatusEnum) {
    if (AttendanceStatusEnum.PRESENT === status) return "P";
    if (AttendanceStatusEnum.LATE === status) return "At";
    if (AttendanceStatusEnum.ABSENT === status) return "F";
    if (AttendanceStatusEnum.EXCUSED === status) return "Ab";
  }
}
