import useFetchComments from "./fetch-comments";
import useFetchAttendancesHistory from "./fetch-attendances-history";

const useCombineHistory = (
  attendanceId: string,
  studentId: string,
  startDate: string,
  endDate: string
) => {
  const { attendancesHistory, isSuccess, refetchAttendances } = useFetchAttendancesHistory(attendanceId);
  const { comments, refetchComments } = useFetchComments(studentId, startDate, endDate);

  const refetchCombine = () => {
    refetchAttendances();
    refetchComments();
  };

  if (comments && attendancesHistory)
    return { combineHistory: [...attendancesHistory, ...comments], refetchHistory: refetchCombine };
  else if (comments && !isSuccess) return { combineHistory: comments, refetchHistory: refetchComments };
  return { combineHistory: [], refetchHistory: refetchCombine };
};

export default useCombineHistory;
