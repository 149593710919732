import { FunctionComponent, Fragment } from "react";
import { format } from "date-fns";
import {
  Divider,
  Subtitle,
  CheckingInfo,
  Container,
  CloseIcon,
  CheckinTime,
  AwaitingIcon,
} from "./checkin-data.styles";
import { AiOutlineClockCircle, AiFillCloseCircle } from "react-icons/ai";

interface CheckinEntityData {
  status: "present" | "late" | "absent" | "awaiting";
  checkedAt: string | null;
  period: { name: string };
}

interface CheckinsDataProps {
  checkins_data: CheckinEntityData[];
}

const CheckinData: FunctionComponent<CheckinsDataProps> = ({ checkins_data }): JSX.Element => {
  return (
    <Container>
      {checkins_data.map((checkin, index) => {
        return (
          <Fragment key={index}>
            <CheckingInfo>
              {checkin.status === "absent" && checkin.checkedAt === null && (
                <CloseIcon role="img">
                  <AiFillCloseCircle data-testid="absent-icon" />
                </CloseIcon>
              )}
              {checkin.status === "awaiting" ? (
                <AwaitingIcon role="img">
                  <AiOutlineClockCircle data-testid="awaiting-icon" />
                </AwaitingIcon>
              ) : (
                <CheckinTime status={checkin.status}>
                  {checkin.checkedAt && format(new Date(checkin.checkedAt), "HH:mm")}
                </CheckinTime>
              )}
              <Subtitle>{checkin.period.name}</Subtitle>
            </CheckingInfo>
            {index === 0 && <Divider />}
          </Fragment>
        );
      })}
    </Container>
  );
};

export default CheckinData;
