import { FC } from "react";
import { Box, Flex } from "@chakra-ui/react";
import FacilitatorBadge from "../facilitator-badge/facilitator-badge";
import DatePicker from "../date-picker/date-picker";
import useFetchNonSchoolDays from "../../hooks/fetch-non-school-days";

interface CheckinHeaderProps {
  module: string;
  dateChangeHandler: (date: Date) => void;
  filter: { day: Date };
  disabledDays: number[];
}

const CheckinHeader: FC<CheckinHeaderProps> = ({ module, dateChangeHandler, filter, disabledDays }) => {
  const { nonSchoolDays } = useFetchNonSchoolDays();
  return (
    <Flex direction="row" justifyContent={"space-between"} alignItems={"flex-end"}>
      <FacilitatorBadge module={module} />
      <Box zIndex={10}>
        <DatePicker
          handleDayChange={dateChangeHandler}
          filter={filter}
          disabledDays={disabledDays}
          nonSchoolDays={nonSchoolDays}
        />
      </Box>
    </Flex>
  );
};

export default CheckinHeader;
