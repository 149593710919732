import { Box } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";

import logo from "../../assets/Vector.svg";

import Modules from "../modules/modules";

function Menu() {
  return (
    <Box
      bg="#23303D"
      w="20rem"
      minH="100vh"
      padding="0.5rem"
      paddingTop="3rem"
      textTransform="uppercase"
      position="initial"
    >
      <Flex direction="column" alignItems="start" justifyContent="initial">
        <Image src={logo} alt="logo" w="3rem" h="auto" mb="3rem" mt="0" alignSelf="center" />

        <Modules />
      </Flex>
    </Box>
  );
}

export default Menu;
