import { useModules } from "../hooks/modules";
import { Module, Facilitator } from "../types";
import { useParams } from "react-router";

type UseFacilitatorId = (arg0: string) => { facilitatorId: string | null };

export const useFacilitatorId: UseFacilitatorId = facilitatorName => {
  const { modules } = useModules();
  const { module } = useParams<{ module: string }>();

  const facilitatorModule = modules.find(
    (apiModule: Module) => apiModule.name.toLowerCase() === module?.toLowerCase()
  );

  const facilitator = facilitatorModule?.facilitators.find(
    (apiFacilitator: Facilitator) => apiFacilitator.name.toLowerCase() === facilitatorName.toLowerCase()
  );

  if (facilitator) return { facilitatorId: facilitator.id };

  return { facilitatorId: null };
};
