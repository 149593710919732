import styled from "styled-components";
import { AttendanceStatusEnum } from "../../model/AttendanceStatus";

export const Container = styled.article`
  height: 100%;
  vertical-align: middle;

  p {
    margin: 0;
    font-size: 1rem;
    color: #000000;
  }
`;

type AttendanceStatusProp = {
  status: AttendanceStatusEnum;
  isAtModal?: boolean;
};

export const AttendanceStatusContainer = styled.div<AttendanceStatusProp>`
  width: 3.5rem;
  height: 3.5rem;
  background-color: ${props => (props.isAtModal === true ? "#F8F8F8" : "#FFFFFF")};
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => (props.status === AttendanceStatusEnum.AWAITING ? "2rem" : "initial")};
  position: relative;
  cursor: ${props => (props.isAtModal === true ? "normal" : "pointer")};

  p {
    margin: 0;
    font-weight: 700;
    font-size: 1.5rem;
    color: ${props =>
      props.status === AttendanceStatusEnum.PRESENT || props.status === AttendanceStatusEnum.AWAITING
        ? "#B9C3D7"
        : props.status === AttendanceStatusEnum.LATE
        ? "#0000FF"
        : props.status === AttendanceStatusEnum.EXCUSED
        ? "#4ECD00"
        : "#FA463C"};
  }

  svg {
    position: ${props => (props.status !== AttendanceStatusEnum.AWAITING ? "absolute" : "initial")};
    top: ${props => (props.status !== AttendanceStatusEnum.AWAITING ? "-0.5rem" : "initial")};
    right: ${props => (props.status !== AttendanceStatusEnum.AWAITING ? "-0.5rem" : "initial")};
  }

  svg:nth-child(2) {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    width: 1rem;
    height: 1rem;
  }
`;
