import { api } from "./api";
import { Period } from "../types";

interface ApiData {
  data: Period[];
}

type GetPeriods = (arg0: { facilitatorId: string }) => Promise<Period[] | void>;

export const getPeriods: GetPeriods = async ({ facilitatorId }) => {
  return await api
    .get<ApiData>("/periods?facilitator_id=" + facilitatorId)
    .then((response): Period[] => response.data.data)
    .catch(error => {
      if (error.response) console.log(error.response);
      else if (error.request) console.log(error.request);
      else console.log("Error", error.message);
    });
};
