import qs from "querystring";
import { useState } from "react";

const setQueryStringUrl = (qsValue: string) => {
  const newUrl =
    window.location.protocol + "//" + window.location.host + window.location.pathname + qsValue;

  window.history.pushState({ path: newUrl }, "", newUrl);
};

const setQueryStringValue = (key: string, value: string) => {
  const values = qs.parse(window.location.search.slice(1));
  const newQsValue = qs.stringify({ ...values, [key]: value });
  setQueryStringUrl(`?${newQsValue}`);
};

const getQueryStringValue = (key: string) => {
  const values = qs.parse(window.location.search.slice(1));
  return values[key];
};

interface UseQueryString {
  (key: string, initialValue: string): [string, (newValue: string) => void];
}

export const useQueryString: UseQueryString = (key: string, initialValue: string) => {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = (newValue: string) => {
    setValue(newValue);
    setQueryStringValue(key, newValue);
  };

  return [value as string, onSetValue];
};

export default useQueryString;
