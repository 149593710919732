import { api } from "./api";
import { Attendance } from "../types";
import { AttendanceStatus } from "../model/AttendanceStatus";

interface ApiAttendance {
  attended_at: string;
  student: {
    id: string;
    name: string;
  };
  status: "awaiting" | "present" | "late" | "absent" | "excused";
  id: string;
  inserted_at: string;
  updated_at: string;
}

interface ApiData {
  data: ApiAttendance[];
}

type GetAttendances = (arg0: {
  facilitatorId: string;
  startDate: string;
  endDate: string;
}) => Promise<Attendance[] | void>;

export const getAttendances: GetAttendances = async ({ facilitatorId, startDate, endDate }) => {
  const token = localStorage.getItem("@teaching/auth-token");
  const params = `?facilitator_id=${facilitatorId}&start_date=${startDate}&end_date=${endDate}`;

  return await api
    .get<ApiData>("/attendances" + params, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response): Attendance[] => {
      return response.data.data.map((apiAttendance: ApiAttendance) => {
        return {
          id: apiAttendance.id,
          student: apiAttendance.student,
          attendedAt: apiAttendance.attended_at,
          status: new AttendanceStatus(apiAttendance.status),
          insertedAt: apiAttendance.inserted_at,
          updatedAt: apiAttendance.updated_at,
        };
      });
    })
    .catch(error => {
      if (error.response) console.log(error.response);
      else if (error.request) console.log(error.request);
      else console.log("Error", error.message);
    });
};
