import { api } from "./api";

interface LoginResponse {
  token?: string;
  user?: {
    name: string;
    email: string;
    role: string;
  };
  errors?: {
    detail: string;
  };
}

interface UserData {
  email: string;
  password: string;
}

type Login = (arg0: UserData) => Promise<LoginResponse | void>;

export const login: Login = async userData => {
  const { data } = await api.post("/users/sign_in", userData, {
    validateStatus: (status: number) => {
      return status === 200 || status === 401;
    },
  });

  if (data.data?.token) return data.data;
  return data;
};
