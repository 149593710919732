import { FC } from "react";
import { Badge, Text, Flex } from "@chakra-ui/react";

import { useFacilitatorName } from "../../hooks/facilitator-name";

interface FacilitatorBadgeProps {
  module: string;
}

const FacilitatorBadge: FC<FacilitatorBadgeProps> = ({ module }) => {
  const { facilitatorName } = useFacilitatorName();

  return (
    <Flex direction="column" justifyContent="center" mr="2rem">
      <Text fontSize="0.75em" fontWeight="semibold">
        Módulo / Facilitador(a)
      </Text>
      <Flex>
        <Badge
          backgroundColor="#23303D"
          color="#FFFFFF"
          mr="1rem"
          padding="0.25rem 0.5rem"
          borderRadius="0.5rem"
        >
          {module.toUpperCase()} / {facilitatorName}
        </Badge>
      </Flex>
    </Flex>
  );
};

export default FacilitatorBadge;
