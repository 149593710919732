import { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { Flex, Spinner } from "@chakra-ui/react";

import { useQuery } from "react-query";

import CheckinPage from "../pages/checkin-page";
import HomePage from "../pages/home-page";

import { useModules } from "../hooks/modules";
import useAuthenticationToken from "../hooks/authentication-token";
import { getModules } from "../services/modules";
import LoginPage from "../pages/login-page";

function Router() {
  const [success, setSuccess] = useState(false);
  const { setModules } = useModules();
  const { isTokenValid } = useAuthenticationToken();
  const { data } = useQuery("modules", getModules, { enabled: !!isTokenValid });

  useEffect(() => {
    if (data) {
      setModules([...data]);
      setSuccess(true);
    }
  }, [data]);

  return !isTokenValid ? (
    <Switch>
      <Route path="/login" render={() => <LoginPage />} />
      <Route path="/">
        <Redirect to="/login" />
      </Route>
    </Switch>
  ) : (
    <>
      {success ? (
        <Switch>
          <Route exact path="/" render={() => <HomePage />} />
          <Route
            path="/:module/:facilitator"
            render={props => <CheckinPage key={props.location.key} />}
          />
          <Route path="/login">
            <Redirect to="/" />
          </Route>
        </Switch>
      ) : (
        <Flex alignItems="center" justifyContent="center" height="100vh">
          <Spinner width="6rem" height="6rem" />
        </Flex>
      )}
    </>
  );
}

export default Router;
