import { format, getDate } from "date-fns";

interface MonthName {
  [key: string]: string;
}

const numberToMonthName: MonthName = {
  "01": "Janeiro",
  "02": "Fevereiro",
  "03": "Março",
  "04": "Abril",
  "05": "Maio",
  "06": "Junho",
  "07": "Julho",
  "08": "Agosto",
  "09": "Setembro",
  "10": "Outubro",
  "11": "Novembro",
  "12": "Dezembro",
};

export class DateTime {
  date: Date;

  constructor(date: Date = new Date()) {
    this.date = date;
  }

  toDateUTC() {
    return new Date(
      Date.UTC(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 0, 0, 0)
    );
  }

  toISO8601String() {
    const day_tmp = this.date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const array_date = day_tmp.split("/");
    return `${array_date[2]}/${array_date[0]}/${array_date[1]}`.replace(/\//g, "-");
  }
}

export function formatDayToString(day: Date) {
  const formattedDay = day.toLocaleDateString("pt-BR", {
    weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  return formattedDay.charAt(0).toUpperCase() + formattedDay.slice(1);
}

export function formatDateToPTBRString(day: string) {
  const expectedFormat = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z?$/;
  const expectedISOFormat = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z?$/;
  if (!expectedFormat.test(day) && !expectedISOFormat.test(day))
    console.error("unexpected date format", day);

  const date = new Date(day);

  return date.toLocaleDateString("pt-BR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

export function getDayFromDate(date: Date | string) {
  return getDate(new Date(date)).toString().padStart(2, "0");
}

export function monthToNaiveDateTime(month: string) {
  return new Date(`${month}-01T00:00:00`);
}

export const getMonthStartAndLastDay = (dateString: string) => {
  const date = monthToNaiveDateTime(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const monthName = `${numberToMonthName[month]} ${year}`;
  const daysInMonth = new Date(year, Number(month), 0).getDate();

  return {
    monthName: monthName,
    firstDayOfMonth: `${year}-${month}-01`,
    lastDayOfMonth: `${year}-${month}-${daysInMonth}`,
  };
};

export function ptBrToISO8601(dateBr: string) {
  const date = new Date(
    parseInt(dateBr.substring(6, 10)),
    parseInt(dateBr.substring(3, 5)) - 1,
    parseInt(dateBr.substring(0, 2))
  );
  return format(date, "yyyy-MM-dd");
}
