import { Box } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";

const Welcome = () => {
  return (
    <Box bg="#F8F8F8" minH="100vh" width="100%" padding="3rem" margin={0}>
      <Heading mb="3rem">Bem vindo!</Heading>
      <Text>Selecione um facilitador para visualizar os checkins</Text>
    </Box>
  );
};

export default Welcome;
