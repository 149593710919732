import { api } from "./api";
import { PreAttendance } from "../types";
import { AttendanceStatus } from "../model/AttendanceStatus";

interface ApiCheckin {
  checked_at: string;
  current_activity: string;
  status: "awaiting" | "present" | "late" | "absent";
  period: {
    name: string;
  };
}

interface ApiPreAttendance {
  id: string;
  checkins: ApiCheckin[];
  student: {
    id: string;
    name: string;
  };
  status: "awaiting" | "present" | "late" | "absent" | "excused";
  inserted_at: string;
  updated_at: string;
}

interface ApiData {
  data: ApiPreAttendance[];
}

type GetCheckins = (arg0: {
  facilitatorId: string;
  attendedAt?: string;
}) => Promise<PreAttendance[] | void>;

type GetCheckinsApiParams = {
  facilitator_id: string;
  attended_at?: string;
};

export const getCheckins: GetCheckins = async ({ facilitatorId, attendedAt }) => {
  const token = localStorage.getItem("@teaching/auth-token");
  let params: GetCheckinsApiParams = { facilitator_id: facilitatorId };
  if (attendedAt) params = { ...params, attended_at: attendedAt };

  return await api
    .get<ApiData>("/checkins", {
      headers: { Authorization: "Bearer " + token },
      params: params,
    })
    .then((response): PreAttendance[] => {
      return response.data.data
        .map((preAttendance: ApiPreAttendance) => ({
          id: preAttendance.id,
          student: {
            id: preAttendance.student.id,
            name: preAttendance.student.name,
          },
          insertedAt: preAttendance.inserted_at,
          updatedAt: preAttendance.updated_at,
          status: new AttendanceStatus(preAttendance.status),
          checkins: preAttendance.checkins.map(checkin => ({
            status: checkin.status,
            checkedAt: checkin.checked_at,
            currentActivity: checkin.current_activity,
            period: checkin.period,
          })),
        }))
        .sort((a: PreAttendance, b: PreAttendance) => (a.student.name > b.student.name ? 1 : -1))
        .filter(
          (value, index, self) => self.findIndex(v => v.student.name === value.student.name) === index
        );
    })
    .catch(error => {
      if (error.response) console.log(error.response);
      else if (error.request) console.log(error.request);
      else console.log("Error", error.message);
    });
};
