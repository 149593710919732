import { Box, Divider, Text, FormControl, Flex, FormLabel, Textarea, Button } from "@chakra-ui/react";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { createComment, CreateCommentResult } from "../../../services/comments";
import * as yup from "yup";

interface CommentProps {
  studentId: string;
  date: string;
  onCancel: () => void;
  onSubmit: () => void;
}

type CommentFormData = {
  comment: string;
};

const CommentFormSchema = yup.object().shape({
  comment: yup.string().required("Campo obrigatório"),
});

const CommentForm = ({ studentId, date, onCancel, onSubmit }: CommentProps) => {
  const [submitStatus, setSubmitStatus] = useState<CreateCommentResult>({
    isSuccess: true,
    error: null,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CommentFormSchema),
  });

  const handleCreateComment: SubmitHandler<CommentFormData> = async data => {
    const result = await createComment({
      date: date,
      studentId: studentId,
      ...data,
    });

    if (result.isSuccess) onSubmit();
    else setSubmitStatus(result);
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleCreateComment)} w="90%">
      <Divider w="25rem" margin="0 auto" marginY="1rem" />
      <FormControl marginTop="2rem">
        <FormLabel textAlign="center" fontSize="0.75rem" color="#23303D">
          Comentário:
        </FormLabel>
        <Textarea data-testid="text-comment" w="23.25rem" minH="7.375rem" {...register("comment")} />
      </FormControl>
      <Flex direction="column" alignItems="center">
        {submitStatus.isSuccess === false && (
          <Text margin="1rem auto" textAlign="center" fontSize="1rem" color="#FA463C">
            {submitStatus.error}
          </Text>
        )}
        {!!errors.comment && (
          <Text margin="1rem auto" textAlign="center" fontSize="1rem" color="#FA463C">
            {errors.comment?.message}
          </Text>
        )}
        <Button
          w="220px"
          fontSize="1.25rem"
          marginTop="1.5rem"
          bgColor="#00B2FF"
          borderRadius="20px"
          color="#FFF"
          type="submit"
        >
          Salvar
        </Button>
        <Button
          w="220px"
          marginTop="1.125rem"
          fontSize="1rem"
          bgColor="transparent"
          color="#3A3A3A"
          textDecor="underline"
          _hover={{ backgroundColor: "transparent", textDecoration: "none" }}
          onClick={onCancel}
        >
          Cancelar
        </Button>
      </Flex>
    </Box>
  );
};

export default CommentForm;
