import styled from "styled-components";

export const CloseIcon = styled.div`
  color: #fa463c;
  font-size: 2rem;
`;

export const AwaitingIcon = styled.div`
  color: #000;
  opacity: 0.5;
  font-size: 2rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
`;

export const Divider = styled.div`
  background-color: #e3e3e3;
  width: 1.5rem;
  height: 2px;
  margin: 1rem 1rem;
`;

export const CheckingInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
`;

export const Subtitle = styled.p`
  margin: 0px;
  font-size: 0.5rem;
  font-weight: 500;
  white-space: nowrap;
`;

interface CheckinTimeProps {
  status: "present" | "late" | "absent" | "awaiting";
}

export const CheckinTime = styled.span<CheckinTimeProps>`
  padding-top: 0.25rem;
  height: 1.5rem;
  font-weight: bold;
  font-size: 1rem;
  color: ${props =>
    props.status === "present" ? "#B9C3D7" : props.status === "absent" ? "#fa463c" : "#0000FF"};
`;
