import { FunctionComponent } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { RiPencilFill } from "react-icons/ri";
import { Container, AttendanceStatusContainer } from "./attendance-status.styles";
import { AttendanceStatus, AttendanceStatusEnum } from "../../model/AttendanceStatus";

interface AttendanceDataProps {
  status: AttendanceStatus;
  hasHistory?: boolean;
  onClick?: () => void;
}

const AttendanceData: FunctionComponent<AttendanceDataProps> = ({
  status,
  hasHistory,
  onClick,
}): JSX.Element => {
  return (
    <Container onClick={onClick && onClick} data-testid="attendance-status">
      {status.name === AttendanceStatusEnum.AWAITING ? (
        <AttendanceStatusContainer status={status.name} isAtModal={!onClick && true}>
          <AiOutlineClockCircle data-testid="awaiting-icon" />
          {hasHistory && <RiPencilFill data-testid="edit-icon" />}
        </AttendanceStatusContainer>
      ) : (
        <AttendanceStatusContainer status={status.name} isAtModal={!onClick && true}>
          {hasHistory && <RiPencilFill data-testid="edit-icon" />}
          <p>{status.logo}</p>
        </AttendanceStatusContainer>
      )}
    </Container>
  );
};

export default AttendanceData;
