import { FC } from "react";

import { Flex } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/react";

import { PreAttendance } from "../../types";
import PreAttendanceRow from "./pre-attendance-row";
import Table from "../table/table";
import Title from "../title/title";
import Card from "../card/card";
import { AttendanceStatusEnum } from "../../model/AttendanceStatus";

interface AttendancesProps {
  attendances?: PreAttendance[];
  chosenDate: Date;
}

const Checkins: FC<AttendancesProps> = ({ attendances, chosenDate }) => {
  const pendingAttendances = attendances?.filter(
    attendance => attendance.status.name === AttendanceStatusEnum.AWAITING
  );
  const completedAttendances = attendances?.filter(
    attendance => attendance.status.name !== AttendanceStatusEnum.AWAITING
  );

  return (
    <Card>
      <Title icon="checkin" text="Atividades" details={[]} />

      {attendances && (
        <Flex direction="column" w="100%" mt="2rem">
          <Table headers={["Aluno", "Registros", "Status", "Atividade"]}>
            {pendingAttendances?.map((attendance, index) => {
              return <PreAttendanceRow attendance={attendance} key={index} chosenDate={chosenDate} />;
            })}
            {completedAttendances?.map((attendance, index) => {
              return <PreAttendanceRow attendance={attendance} key={index} chosenDate={chosenDate} />;
            })}
          </Table>
        </Flex>
      )}
      {!attendances && (
        <>
          <Skeleton height="2rem" maxW="100%" mb="0.5rem" mt="3rem" />
          <Skeleton height="3rem" maxW="100%" mb="0.5rem" />
          <Skeleton height="3rem" maxW="100%" mb="0.5rem" />
          <Skeleton height="3rem" maxW="100%" mb="0.5rem" />
        </>
      )}
    </Card>
  );
};

export default Checkins;
