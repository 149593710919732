import { useQuery } from "react-query";

import { Blocker } from "../types";

const useFilterBlockers = (checkins: Blocker[] | undefined) => {
  const { data, isSuccess, error, isLoading, isError } = useQuery(["blockers", checkins], async () => {
    if (checkins) return checkins.filter(checkin => !!checkin.currentBlockers);
  });

  return { blockers: data, isSuccess, error, isLoading, isError };
};

export default useFilterBlockers;
