import { FC, useEffect } from "react";
import { Tr } from "@chakra-ui/react";
import { Td } from "@chakra-ui/react";
import { PreAttendance } from "../../types";
import CheckinData from "../checkin-data/checkin-data";
import AttendanceData from "../attendance-status/attendance-status";
import useCombineHistory from "../../hooks/combine-history";
import { format } from "date-fns";
import AttendanceCheckinModal from "../attendances/attendance-checkin/attendance-checkin-modal";
import { useState } from "react";
import { ActionListeners } from "../../hooks/listerners";

interface PreAttendanceRowProps {
  attendance: PreAttendance;
  chosenDate: Date;
}

const PreAttendanceRow: FC<PreAttendanceRowProps> = ({ attendance, chosenDate }) => {
  const [currentAttendance, setCurrentAttendance] = useState(attendance);
  const [showEditAttendanceModal, setShowEditAttendanceModal] = useState(false);

  const handleOpenModal = () => {
    setShowEditAttendanceModal(false);
  };

  const { combineHistory, refetchHistory } = useCombineHistory(
    currentAttendance.id,
    currentAttendance.student.id as string,
    format(chosenDate, "yyyy-MM-dd"),
    format(chosenDate, "yyyy-MM-dd")
  );

  const hasHistory: boolean = combineHistory.length > 0;

  const modalListener = new ActionListeners(args => {
    setCurrentAttendance(args["attendance"]);
  });

  useEffect(() => {
    setCurrentAttendance(attendance);
  }, [chosenDate]);

  return (
    <>
      <Tr backgroundColor="#F8F8F8">
        <Td fontWeight="bold" w="18rem">
          {currentAttendance.student.name}
        </Td>
        <Td>
          <CheckinData
            checkins_data={currentAttendance.checkins.map(data => ({
              status: data.status,
              checkedAt: data.checkedAt,
              period: data.period,
            }))}
          />
        </Td>
        <Td>
          <AttendanceData
            status={currentAttendance.status}
            hasHistory={hasHistory}
            onClick={() => setShowEditAttendanceModal(true)}
          />
        </Td>
        <Td>
          {currentAttendance.checkins.map(checkin => checkin.currentActivity || "---").join(" / ")}
        </Td>
      </Tr>
      <AttendanceCheckinModal
        currentAttendance={attendance}
        modalOpened={showEditAttendanceModal}
        handleCloseModal={handleOpenModal}
        chosenDate={chosenDate}
        history={combineHistory}
        refetch={refetchHistory}
        listeners={[modalListener]}
      />
    </>
  );
};

export default PreAttendanceRow;
