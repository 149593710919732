import { api } from "./api";
import { AttendanceHistory } from "../types";
import { AttendanceStatus } from "../model/AttendanceStatus";

interface ApiAttendancesHistory {
  attendance_id: string;
  user: {
    name: string;
    role: "admin" | "facilitator" | "instructor";
  };
  previous_status: "awaiting" | "present" | "late" | "absent" | "excused";
  new_status: "awaiting" | "present" | "late" | "absent" | "excused";
  reason: string;
  changed_at: string;
}

interface ApiData {
  data: ApiAttendancesHistory[];
}

type GetAttendancesHistory = (arg0: { attendanceId: string }) => Promise<AttendanceHistory[] | void>;

export const getAttendancesHistory: GetAttendancesHistory = async ({ attendanceId }) => {
  const token = localStorage.getItem("@teaching/auth-token");

  return await api
    .get<ApiData>(`/attendances/${attendanceId}/history`, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response): AttendanceHistory[] => {
      return response.data.data.map((apiAttendanceHistory: ApiAttendancesHistory) => {
        return {
          attendanceId: apiAttendanceHistory.attendance_id,
          user: apiAttendanceHistory.user,
          previousStatus: new AttendanceStatus(apiAttendanceHistory.previous_status, false),
          newStatus: new AttendanceStatus(apiAttendanceHistory.new_status, false),
          reason: apiAttendanceHistory.reason,
          changedAt: apiAttendanceHistory.changed_at,
        };
      });
    })
    .catch(error => {
      if (error.response) console.log(error.response);
      else if (error.request) console.log(error.request);
      else console.log("Error", error.message);
    });
};
