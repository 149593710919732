import { FC } from "react";
import { Flex } from "@chakra-ui/react";
import FacilitatorBadge from "../facilitator-badge/facilitator-badge";
import PeriodSelector from "../period-selector/period-selector";
import useFetchPeriods from "../../hooks/fetch-periods";
import { useFacilitatorName } from "../../hooks/facilitator-name";
import { useFacilitatorId } from "../../hooks/facilitator-id";

interface BlockersHeaderProps {
  module: string;
  selectedPeriodId: string;
  handleWithPeriodChange: (period_id: string) => void;
}

const BlockersHeader: FC<BlockersHeaderProps> = ({
  module,
  selectedPeriodId,
  handleWithPeriodChange,
}) => {
  const { facilitatorName } = useFacilitatorName();
  const { facilitatorId } = useFacilitatorId(facilitatorName);
  const { periods } = useFetchPeriods(facilitatorId);

  return (
    <Flex direction="row">
      <Flex direction="row">
        <FacilitatorBadge module={module} />
      </Flex>

      {periods && periods.length > 0 ? (
        <PeriodSelector
          periods={periods}
          selectedPeriodId={selectedPeriodId}
          handleWithPeriodChange={handleWithPeriodChange}
        />
      ) : (
        <Flex alignItems="center">
          <p>Não existem períodos cadastrados para esse Módulo</p>
        </Flex>
      )}
    </Flex>
  );
};

export default BlockersHeader;
