import { useQuery } from "react-query";
import { getAttendances } from "../services/attendances";

const useFetchAttendances = (facilitatorId: string, startDate: string, endDate: string) => {
  const { data, isSuccess, error, isLoading, isError, refetch } = useQuery(
    ["attendances", { facilitatorId, startDate, endDate }],
    async () => {
      return await getAttendances({
        facilitatorId,
        startDate,
        endDate,
      });
    },
    { retry: false, enabled: !!facilitatorId }
  );

  return { attendances: data, isSuccess, error, isLoading, isError, refetchAttendances: refetch };
};

export default useFetchAttendances;
