import { FC } from "react";
import { Flex } from "@chakra-ui/react";
import FacilitatorBadge from "../facilitator-badge/facilitator-badge";

interface AttendancesHeaderProps {
  module: string;
}

const AttendancesHeader: FC<AttendancesHeaderProps> = ({ module }) => {
  return (
    <Flex direction="row" justifyContent={"space-between"} alignItems={"flex-end"}>
      <FacilitatorBadge module={module} />
    </Flex>
  );
};

export default AttendancesHeader;
