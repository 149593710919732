import { FC } from "react";
import { Tr } from "@chakra-ui/react";
import { Td } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { AttendancesGrade, Attendance } from "../../types";
import AttendanceTotalGrade from "./attendance-total-grade";
import Month from "../calendar/month/month";

interface AttendanceGradeRowProps {
  attendanceGrade: AttendancesGrade;
  attendances: Attendance[];
  startDate: string;
  endDate: string;
  refetchAttendances: () => void;
  refetchAttendancesGrade: () => void;
}

const AttendanceGradeRow: FC<AttendanceGradeRowProps> = ({
  attendanceGrade,
  attendances,
  startDate,
  endDate,
  refetchAttendances,
  refetchAttendancesGrade,
}) => {
  return (
    <Tr backgroundColor="#F8F8F8">
      <Td minWidth="15rem" maxWidth="15rem" fontWeight="bold">
        <Text>{attendanceGrade.student.name}</Text>
      </Td>
      <Td width="58rem">
        <Month
          attendances={attendances}
          startDate={startDate}
          endDate={endDate}
          refetchAttendances={refetchAttendances}
          refetchAttendancesGrade={refetchAttendancesGrade}
        />
      </Td>
      <Td w="3rem">
        <Text>{attendanceGrade.lateCount.toString().padStart(2, "0")}</Text>
      </Td>
      <Td w="3rem">
        <Text>{attendanceGrade.absentCount.toString().padStart(2, "0")}</Text>
      </Td>
      <Td w="3rem">
        <AttendanceTotalGrade
          grade={attendanceGrade.attendancesGrade.toFixed(2).toString().replace(".", ",")}
          percentGrade={attendanceGrade.percentGrade.toString()}
        />
      </Td>
    </Tr>
  );
};

export default AttendanceGradeRow;
