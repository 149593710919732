import styled, { css } from "styled-components";
import { AttendanceStatusEnum } from "../../../model/AttendanceStatus";

type StatusOptionProp = {
  status: AttendanceStatusEnum;
  selected: boolean;
};

export const StatusOption = styled.div<StatusOptionProp>`
  margin: 0 1rem;
  width: 3.5rem;
  height: 3.5rem;
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  position: relative;
  cursor: pointer;

  ${props =>
    props.selected &&
    css`
      border: 0.25rem solid #23303d;
    `}

  p {
    margin: 0;
    font-weight: 700;
    font-size: 1.5rem;
    color: ${props =>
      props.status === AttendanceStatusEnum.PRESENT
        ? "#B9C3D7"
        : props.status === AttendanceStatusEnum.LATE
        ? "#0000FF"
        : props.status === AttendanceStatusEnum.ABSENT
        ? "#FA463C"
        : "#4ECD00"};
  }
`;
