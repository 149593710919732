import { AtSignIcon, LockIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
} from "@chakra-ui/react";

import { useHistory } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import logo from "../../assets/logo-written.svg";
import { login } from "../../services/login";

type Inputs = {
  email: string;
  password: string;
};

const LoginBox = () => {
  const toast = useToast();
  const history = useHistory();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Insira um email válido")
      .required("Insira o email de login para continuar"),
    password: yup.string().required("Insira a senha para continuar"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Inputs> = async data => {
    const toastId = "loginError";
    const response = await login(data);

    if (response?.token) {
      localStorage.setItem("@teaching/auth-token", response.token);
      toast.closeAll();
      history.push("/");
      history.go(0);
    }

    if (response?.errors && !toast.isActive(toastId)) {
      toast({
        position: "top-right",
        description: "Nome de usuário ou senha inválidos.",
        status: "error",
        duration: 9000,
        isClosable: true,
        id: toastId,
      });
    }
  };

  return (
    <Box backgroundColor="#415F7D" h="25rem" w="21rem" pt="3rem">
      <Flex alignItems="center" direction="column" h="100%">
        <Image src={logo} alt="" w="5.5rem" />

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl id="email" mt="1rem" isInvalid={!!errors?.email}>
            <FormLabel color="white" fontSize="0.75rem">
              Email
            </FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<AtSignIcon color="gray.500" />} />
              <Input
                background="#ffffff"
                placeholder="yoda@kenzie.com.br"
                {...register("email")}
                errorBorderColor="#FF7070"
              />
            </InputGroup>
            <Box h="1rem" mb="1rem">
              <FormErrorMessage m="0" color="#FF7070">
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </Box>
          </FormControl>

          <FormControl id="password" isInvalid={!!errors?.password}>
            <FormLabel color="white" fontSize="0.75em">
              Senha
            </FormLabel>

            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<LockIcon color="gray.500" />} />
              <Input
                background="#ffffff"
                placeholder="•••••"
                type="password"
                {...register("password")}
                errorBorderColor="#FF7070"
              />
            </InputGroup>
            <Box h="1rem" mb="1rem">
              <FormErrorMessage m="0" color="#FF7070">
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </Box>
          </FormControl>

          <Button background="#00B2FF" color="#ffffff" type="submit" mt="1rem" w="100%">
            Entrar
          </Button>
        </form>
        <Box m="1rem" color="white" fontSize="0.75em">
          <Link href="https://backoffice.kenzie.com.br/password-recovery/request" isExternal>
            Esqueci minha senha
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

export default LoginBox;
