import { FunctionComponent, useEffect, useState } from "react";
import { format } from "date-fns";
import Modal from "../../modal/modal";
import { Attendance } from "../../../types";
import AttendanceData from "../../attendance-status/attendance-status";
import EditAttendanceStatus from "../edit-attendace-status/edit-attendance-status";
import { Box, Text, Flex, Link, Icon } from "@chakra-ui/react";
import History from "../history";
import { HiOutlinePencil } from "react-icons/hi";
import { AttendanceStatusEnum } from "../../../model/AttendanceStatus";
import AddCommentButton from "../comment/add-comment-button";
import CommentForm from "../comment/comment-form";
import useCombineHistory from "../../../hooks/combine-history";

interface AttendanceStatusModalProps {
  currentAttendance: Attendance;
  modalOpened: boolean;
  handleCloseModal: () => void;
  chosenDate: Date;
  refetchAttendances: () => void;
  refetchAttendancesGrade: () => void;
}

const AttendanceStatusModal: FunctionComponent<AttendanceStatusModalProps> = ({
  currentAttendance,
  handleCloseModal,
  modalOpened,
  chosenDate,
  refetchAttendances,
  refetchAttendancesGrade,
}): JSX.Element => {
  const [attendanceFormOpened, changeAttendanceFormOpened] = useState<boolean>(false);
  const [commentFormOpened, changeCommentFormOpened] = useState<boolean>(false);
  const [currentAttendanceState, setCurrentAttendanceState] = useState<Attendance | null>(null);

  const { combineHistory, refetchHistory } = useCombineHistory(
    currentAttendance.id as string,
    currentAttendance.student.id as string,
    format(chosenDate, "yyyy-MM-dd").toString(),
    format(chosenDate, "yyyy-MM-dd").toString()
  );

  useEffect(() => {
    setCurrentAttendanceState(currentAttendance);
  }, [currentAttendance]);

  const closeCommentForm = () => {
    changeCommentFormOpened(false);
  };

  const closeAttendanceForm = () => {
    changeAttendanceFormOpened(false);
  };

  const openCommentForm = () => {
    changeCommentFormOpened(true);
    changeAttendanceFormOpened(false);
  };

  const openEditAttendanceForm = () => {
    changeAttendanceFormOpened(true);
    changeCommentFormOpened(false);
  };

  const onClose = () => {
    changeAttendanceFormOpened(false);
    changeCommentFormOpened(false);
    handleCloseModal();
  };

  const attendanceEditSuccess = (attendance: Attendance) => {
    changeAttendanceFormOpened(false);
    setCurrentAttendanceState(attendance);
    refetchAttendances();
    refetchAttendancesGrade();
    refetchHistory();
  };

  const commentCreateSuccess = () => {
    changeCommentFormOpened(false);
    refetchHistory();
  };

  useEffect(() => {
    setCurrentAttendanceState(currentAttendance);
  }, [currentAttendance]);

  const showHistory = !attendanceFormOpened && !commentFormOpened;

  if (currentAttendanceState) {
    const showAttendanceEditButton =
      currentAttendanceState.status.name !== AttendanceStatusEnum.AWAITING && showHistory;

    return (
      <Modal show={modalOpened} width={450} onClose={onClose}>
        <Text textAlign="center" fontSize="1.5rem" fontWeight="bold" mb="1.5rem">
          Registro De Atividade
        </Text>
        <Box w="17rem">
          <Text textAlign="center" fontSize="1rem" fontWeight="bold" mb="1rem">
            Aluno: <span style={{ fontWeight: "normal" }}>{currentAttendanceState.student.name}</span>
          </Text>
          <Flex m="0 auto" alignItems="flex-start" justifyContent="space-between">
            <Flex flexDirection="column" alignItems="center">
              <Text fontSize="1rem" fontWeight="bold" mb="1rem">
                Data: {format(chosenDate, "dd/MM/yyyy")}
              </Text>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              <Text fontSize="1rem" fontWeight="bold" mb="1rem">
                Status
              </Text>
              <AttendanceData status={currentAttendanceState.status} />
              {showAttendanceEditButton && (
                <Link
                  display="flex"
                  alignItems="center"
                  m="0.25rem auto"
                  onClick={openEditAttendanceForm}
                >
                  <Text fontSize="0.75rem" marginRight="0.25rem">
                    Editar
                  </Text>
                  <Icon as={HiOutlinePencil} fontSize="0.75rem" />
                </Link>
              )}
            </Flex>
          </Flex>
        </Box>
        {showHistory && (
          <>
            <AddCommentButton onClick={openCommentForm} />
            <History history={combineHistory} />
          </>
        )}
        {commentFormOpened && (
          <CommentForm
            studentId={currentAttendance.student.id as string}
            date={format(chosenDate, "yyyy-MM-dd")}
            onSubmit={commentCreateSuccess}
            onCancel={closeCommentForm}
          />
        )}
        {attendanceFormOpened && (
          <EditAttendanceStatus
            attendance={currentAttendanceState}
            onSubmitAttendance={attendanceEditSuccess}
            onCancel={closeAttendanceForm}
            attendedAt={format(chosenDate, "dd/MM/yyyy")}
          />
        )}
      </Modal>
    );
  }
  return <></>;
};

export default AttendanceStatusModal;
