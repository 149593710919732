import { api } from "./api";
import { AttendanceStatusEnum, attendancesStatusValues } from "../model/AttendanceStatus";
import { ptBrToISO8601 } from "../utils/format-date";

export type SaveAttendanceResult = {
  isSuccess: boolean;
  error: string | null;
};

interface SaveAttendancePayload {
  attendanceId?: string;
  status: AttendanceStatusEnum;
  reason: string;
  attendedAt: string;
  moduleId: string;
  studentId?: string;
}

export const saveAttendance = async (payload: SaveAttendancePayload): Promise<SaveAttendanceResult> => {
  if (payload.attendanceId) return updateAttendanceStatus(payload);
  return createAttendance(payload);
};

export const updateAttendanceStatus = async ({
  attendanceId,
  status,
  reason,
}: SaveAttendancePayload): Promise<SaveAttendanceResult> => {
  const token = localStorage.getItem("@teaching/auth-token");

  return await api
    .patch(
      `/attendances/${attendanceId}`,
      {
        status: attendancesStatusValues[status],
        reason: reason,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    )
    .then(() => {
      return { isSuccess: true, error: null };
    })
    .catch(error => {
      if (error.response) console.log(error.response);
      else if (error.request) console.log(error.request);
      else console.log("Error", error.message);
      return { isSuccess: false, error: error.message };
    });
};

export const createAttendance = async ({
  status,
  reason,
  attendedAt,
  studentId,
  moduleId,
}: SaveAttendancePayload): Promise<SaveAttendanceResult> => {
  const token = localStorage.getItem("@teaching/auth-token");

  return await api
    .post(
      "/attendances",
      {
        status: attendancesStatusValues[status],
        student_id: studentId,
        module_id: moduleId,
        reason: reason,
        attended_at: ptBrToISO8601(attendedAt),
        previous_status: attendancesStatusValues[AttendanceStatusEnum.ABSENT],
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    )
    .then(() => {
      return { isSuccess: true, error: null };
    })
    .catch(error => {
      if (error.response) console.log(error.response);
      else if (error.request) console.log(error.request);
      else console.log("Error", error.message);
      return { isSuccess: false, error: error.message };
    });
};
