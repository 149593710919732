/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from "react";

interface PropsToWatch {
  [key: string]: any;
}

interface ActionListenerPerformArgs {
  [key: string]: any;
}

type ActionListenerPerform = (args: ActionListenerPerformArgs) => void;

export class ActionListeners {
  public perform: ActionListenerPerform;
  constructor(perform: ActionListenerPerform) {
    this.perform = perform;
  }
}

interface HasListenersObjArgument {
  propsToWatch: PropsToWatch;
  performArgument: { [key: string]: any };
}

export const hasListeners = (config: HasListenersObjArgument, listeners?: ActionListeners[]): void => {
  const prev = useRef(config.propsToWatch);

  useEffect(() => {
    if (listeners && listeners.length > 0) {
      const changedProps = Object.entries(config.propsToWatch).reduce(
        (ps: { [key: string]: any }, [k, v]) => {
          if (prev.current[k as keyof PropsToWatch] !== v) {
            if (typeof v === "function") console.warn("props observer can not be a function");
            ps[k] = [prev.current[k as keyof PropsToWatch], v];
          }

          return ps;
        },
        {}
      );
      if (Object.keys(changedProps).length > 0) {
        listeners.forEach(listener => {
          listener.perform(config.performArgument);
        });
      }

      prev.current = config.propsToWatch;
    }
  }, Object.values(config.propsToWatch));
};
